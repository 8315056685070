<script setup lang="ts">
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';

type Props = {
  runningOrder: object;
  columnDefinitions: any[];
  hiddenColumns: any[];
};

const props = defineProps<Props>();

defineEmits<{
  (event: 'closed'): void;
}>();

const toast = useToast();

const downloadableColumns = ref(props.columnDefinitions);
const asCsv = ref(false);
const colorOnPrint = ref(false);
const downloadingRunningOrder = ref(false);

const downloadBoard = async () => {
  if (downloadingRunningOrder.value) return;

  downloadingRunningOrder.value = true;

  try {
    const { data } = await axios.post(`/api/runningorders/${props.runningOrder.id}/download`, {
      asCSV: asCsv.value,
      downloadableColumns: downloadableColumns.value,
      colorOnPrint: colorOnPrint.value,
    });
    await downloadFile(data.url, data.name);
    downloadingRunningOrder.value = false;
  } catch (e) {
    console.error(e);
    downloadingRunningOrder.value = false;
    toast.warning('Something went wrong, please try again later');
  }
};
downloadableColumns.value = [];
props.columnDefinitions.forEach((columnDefinition, index) => {
  if (!props.hiddenColumns.includes(columnDefinition.id)) {
    if (
      columnDefinition.component === 'runningorder-column-document' ||
      columnDefinition.component === 'runningorder-column-image'
    ) {
    } else {
      downloadableColumns.value.push(columnDefinition.id);
    }
  }
});
</script>

<template>
  <ReportModal
    v-model:as-c-s-v="asCsv"
    can-download
    small
    title="Download Running Order"
    :working="downloadingRunningOrder"
    :with-button="false"
    @download="downloadBoard"
    @close="$emit('closed')">
    <VMultiselect
      v-if="columnDefinitions.length > 0"
      v-model="downloadableColumns"
      :options="columnDefinitions"
      with-add-all
      label="Columns to Include"
      can-edit
      option-label="title"
      label-key="id" />
    <SettingToggle
      v-model="colorOnPrint"
      label="Include Colors on Print"
      title="If enabled, the color definitions of this running order will be included on the print." />
  </ReportModal>
</template>
