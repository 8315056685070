<script lang="ts" setup>
import { TaskResource } from '@/types/tasks';
import { openRoute } from '@/util/route';
import { computed } from 'vue';

type Props = {
  task: TaskResource;
  pillClasses?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  pillClasses: 'max-w-[200px]',
});
const alreadyOnPage = computed(() => {
  return props.task.performance.url === window.location.origin + window.location.pathname;
});
const openPerformance = (event) => {
  if (alreadyOnPage.value) return;
  if (!props.task.performance || !props.task.performance.url) {
    return;
  }
  openRoute(props.task.performance.url + '#tasks', event);
};
</script>

<template>
  <div
    v-if="task.performance"
    class="flex">
    <div
      :class="{ 'hover:bg-backgroundColor cursor-pointer hover:border-textColor-soft ': !alreadyOnPage }"
      class="border flex gap-2 items-center rounded-xl font-headers px-3 py-1"
      @click="openPerformance($event)">
      <span
        :class="pillClasses"
        class="truncate">
        {{ task.performance.name }}
      </span>
      <i
        v-if="!alreadyOnPage"
        class="fa fa-fw fa-regular fa-external-link text-xs"></i>
    </div>
  </div>
</template>
