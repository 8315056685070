<script setup lang="ts">
import { InviteResource } from '@/types/invite';
import moment from 'moment';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { dateFormat, dateTimeFormat } from '@/variables/date-format';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useEmitStore } from '@/store/EmitStore';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  invite: InviteResource;
  show: boolean;
  showRecurringReportDownloader?: boolean | null;
  venueId: number | null;
  togglable?: boolean | null;
};
const props = withDefaults(defineProps<Props>(), {
  togglable: true,
  venueId: null,
  showRecurringReportDownloader: false,
});

const emit = defineEmits<{
  (event: 'update:show', arg: boolean): void;
}>();

const toast = useToast();
const { threeButtonModal } = useMultipleButtonsModal();
const { isSmallScreen } = useSmallScreen();

const dates = ref([]);
const bookings = ref([]);
const datesLength = ref(0);
const loaded = ref(false);
const loading = ref(false);
const downloadingRoomBookingReport = ref(false);
const showSection = ref(true);

const anyBookingHasTitle = computed(
  () => bookings.value.filter((booking: any) => booking.room_title && booking.room_title.length > 0).length > 0
);

const fetchRoomBookings = async () => {
  if (loaded.value) return;
  loaded.value = true;
  loading.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/room-booking`);
  bookings.value = data;

  const flatMapped = data.flatMap((booking) => {
    const array = [];
    let currentDate = moment(booking.start).startOf('day');
    while (currentDate <= moment(booking.end).startOf('day')) {
      const object = { ...booking };
      if (currentDate.isSame(booking.end, 'day')) {
        const start = moment(booking.start, dateTimeFormat);
        const end = moment(booking.end, dateTimeFormat);
        const endMidnight = moment(booking.end, dateTimeFormat).startOf('day');
        if (end.diff(endMidnight, 'minutes') > 60 * 5) {
          object.date = currentDate.format(dateFormat);
          array.push(object);
        } else if (end.diff(endMidnight, 'minutes') < 60 * 5 && start.isSame(end, 'day')) {
          object.date = currentDate.format(dateFormat);
          array.push(object);
        }
      } else {
        object.date = currentDate.format(dateFormat);
        array.push(object);
      }
      currentDate = currentDate.add(1, 'days');
    }
    return array;
  });
  dates.value = _.groupBy(flatMapped, (booking) => booking.date);
  if (data.length) {
    datesLength.value = Object.keys(dates.value).length;
  }

  loading.value = false;
};
if (props.show) {
  nextTick(() => {
    fetchRoomBookings();
  });
}

watch(
  () => props.show,
  (value) => {
    if (value) {
      setTimeout(() => {
        if (!loaded.value) fetchRoomBookings();
      }, Math.random() * 20);
    }
  }
);

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'room-added-to-event':
    case 'room-removed-from-event':
    case 'room-update-on-event': {
      if (!loaded.value) return;
      loaded.value = false;
      fetchRoomBookings();
      break;
    }
    default:
      break;
  }
});

const getFirstDateOfDates = () => {
  if (datesLength.value === 1) {
    return moment(Object.keys(dates.value)[0]).format('dddd Do [of] MMMM');
  }
  return null;
};

const formattedDate = (date: string) => moment(date).format('dddd Do [of] MMMM');
const getStartTimeFromBooking = (booking, date: string) => {
  if (moment(booking.start).isSame(date, 'day')) {
    return moment(booking.start).format('HH:mm');
  }
  if (moment(booking.start).isBefore(date, 'day') && moment(booking.end).isAfter(date, 'day')) {
    return 'All Day';
  }
  if (moment(booking.start).isBefore(date, 'day')) {
    return '00:00';
  }
  return moment(booking.start).format('HH:mm');
};
const getEndTimeFromBooking = (booking, date: string) => {
  if (moment(booking.end).isSame(date, 'day')) {
    return ' - ' + moment(booking.end).format('HH:mm');
  }
  if (moment(booking.start).isBefore(date, 'day') && moment(booking.end).isAfter(date, 'day')) {
    return '';
  }
  if (moment(booking.date).isAfter(date, 'day')) {
    return ' - 00:00';
  }
  return ' - ' + moment(booking.end).format('HH:mm');
};

const doTheActualDownloadingOfReport = async (asCSV: boolean) => {
  if (downloadingRoomBookingReport.value) return;

  downloadingRoomBookingReport.value = true;
  const response = await axios
    .post('/api/reports/recurring-event-booking', {
      event_id: props.invite.event.id,
      venue_id: props.venueId,
      asCSV,
    })
    .catch((error) => {
      console.error(error);
      downloadingRoomBookingReport.value = false;
      toast.warning('Something went wrong, please try again later');
    });
  if (response.status === 204) {
    toast.warning(response.statusText);
    downloadingRoomBookingReport.value = false;
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  downloadingRoomBookingReport.value = false;
};

const downloadRecurringReport = async () => {
  if (downloadingRoomBookingReport.value) return;
  if (!props.venueId) return;

  const result = await threeButtonModal({
    title: `Download Room Booking Report for ${props.invite.event.name}`,
    description: 'Do you want to download it as a PDF or a CSV (Excel) file?',
    useSelect: true,
    button: {
      text: 'Download',
      type: 'success',
    },
    options: [
      {
        value: 'first',
        label: 'PDF',
      },
      {
        value: 'second',
        label: 'CSV',
      },
    ],
  });

  switch (result) {
    case 'first': {
      await doTheActualDownloadingOfReport(false);
      break;
    }
    case 'second': {
      await doTheActualDownloadingOfReport(true);
      break;
    }
    case 'cancel':
    default: {
      break;
    }
  }
};
</script>

<template>
  <BoxContainer
    v-if="showSection"
    header="Room Bookings"
    :actions="
      showRecurringReportDownloader
        ? [
            {
              title: 'Report',
              hoverText: 'Download Report over Room Bookings for all recurrences of event',
              loading: downloadingRoomBookingReport,
              icon: 'fa-download',
              action: () => {
                downloadRecurringReport();
              },
            },
          ]
        : []
    "
    :togglable="togglable">
    <VTable
      v-if="bookings.length === 0"
      edge-to-edge>
      <VTableRow no-background>
        <VTableCell> No Rooms Added</VTableCell>
      </VTableRow>
    </VTable>

    <VTable
      v-for="(date, index) in dates"
      edge-to-edge
      row-size="small">
      <VTableRow no-background>
        <VTableCell />
        <VTableCell
          main-cell
          colspan="100%">
          <div class="text-sm">
            {{ formattedDate(date[0].date) }}
          </div>
        </VTableCell>
      </VTableRow>

      <VTableRow head>
        <VTableCell />
        <VTableCell> When</VTableCell>
        <VTableCell> Room</VTableCell>
        <VTableCell v-if="!isSmallScreen && anyBookingHasTitle" />
      </VTableRow>

      <VTableRow v-for="booking in date">
        <VTableCell style="width: 40px" />
        <VTableCell style="width: 90px">
          <div class="text-sm">
            {{ getStartTimeFromBooking(booking, date[0].date) }}
            {{ getEndTimeFromBooking(booking, date[0].date) }}
          </div>
        </VTableCell>
        <VTableCell
          main-cell
          :style="'width: calc(100% - 25px - 15% - ' + anyBookingHasTitle ? '280px' : '0px' + ';)'">
          {{ booking.room_name }}
          <div
            v-if="isSmallScreen"
            class="text-textColor-soft">
            {{ booking.room_title }}
          </div>
        </VTableCell>
        <VTableCell
          v-if="anyBookingHasTitle && !isSmallScreen"
          style="width: 280px">
          {{ booking.room_title }}
        </VTableCell>
      </VTableRow>
      <VTableRow no-background>
        <VTableCell colspan="100%" />
      </VTableRow>
    </VTable>
  </BoxContainer>
  <!--  <div v-if="showSection">-->
  <!--    <div class="relative flex">-->
  <!--      <div-->
  <!--        class="flex gap-2 items-center py-5 pl-edge cursor-pointer"-->
  <!--        @click="[$emit('update:show', !show), fetchRoomBookings()]">-->
  <!--        <ChevronToggle-->
  <!--          :loading="loading"-->
  <!--          :model-value="show" />-->
  <!--        <h3>Room Bookings</h3>-->
  <!--      </div>-->

  <!--      <ActionButtons-->
  <!--        v-if="show && showRecurringReportDownloader"-->
  <!--        class="border-t"-->
  <!--        :actions="[-->
  <!--   -->
  <!--        ]"-->
  <!--        size="small" />-->
  <!--    </div>-->

  <!--    <div-->
  <!--      v-if="show"-->
  <!--      class="content">-->
  <!--      <VTable-->
  <!--        v-if="bookings.length === 0"-->
  <!--        edge-to-edge>-->
  <!--        <VTableRow no-background>-->
  <!--          <VTableCell> No room bookings found</VTableCell>-->
  <!--        </VTableRow>-->
  <!--      </VTable>-->

  <!--      <VTable-->
  <!--        v-for="(date, index) in dates"-->
  <!--        edge-to-edge-->
  <!--        row-size="small">-->
  <!--        <VTableRow no-background>-->
  <!--          <VTableCell />-->
  <!--          <VTableCell-->
  <!--            main-cell-->
  <!--            colspan="100%">-->
  <!--            <div class="text-sm">-->
  <!--              {{ formattedDate(date[0].date) }}-->
  <!--            </div>-->
  <!--          </VTableCell>-->
  <!--        </VTableRow>-->

  <!--        <VTableRow head>-->
  <!--          <VTableCell />-->
  <!--          <VTableCell> When</VTableCell>-->
  <!--          <VTableCell> Room</VTableCell>-->
  <!--          <VTableCell v-if="!isSmallScreen" />-->
  <!--        </VTableRow>-->

  <!--        <VTableRow v-for="booking in date">-->
  <!--          <VTableCell style="width: 40px" />-->
  <!--          <VTableCell style="width: 90px">-->
  <!--            <div class="text-sm">-->
  <!--              {{ getStartTimeFromBooking(booking, date[0].date) }}-->
  <!--              {{ getEndTimeFromBooking(booking, date[0].date) }}-->
  <!--            </div>-->
  <!--          </VTableCell>-->
  <!--          <VTableCell-->
  <!--            main-cell-->
  <!--            :style="'width: calc(100% - 25px - 15% - ' + anyBookingHasTitle ? '280px' : '0px' + ';)'">-->
  <!--            {{ booking.room_name }}-->
  <!--            <div-->
  <!--              v-if="isSmallScreen"-->
  <!--              class="text-textColor-soft">-->
  <!--              {{ booking.room_title }}-->
  <!--            </div>-->
  <!--          </VTableCell>-->
  <!--          <VTableCell-->
  <!--            v-if="anyBookingHasTitle && !isSmallScreen"-->
  <!--            style="width: 280px">-->
  <!--            {{ booking.room_title }}-->
  <!--          </VTableCell>-->
  <!--        </VTableRow>-->
  <!--        <VTableRow no-background>-->
  <!--          <VTableCell colspan="100%" />-->
  <!--        </VTableRow>-->
  <!--      </VTable>-->
  <!--    </div>-->
  <!--  </div>-->
</template>
