<script lang="ts" setup>
import { DocumentModelType, DocumentResource } from '@/types/document';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { uploadFile } from '@/util/file-upload';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';

type Props = {
  buttonText?: string;
  folderId?: number | null;
  withIcon?: boolean;
  canEdit?: boolean;
  uploading?: boolean;
  model?: DocumentModelType | null;
  modelId?: number | null;
  maxMBSize?: number;
  multiple?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Upload file',
  folderId: null,
  withIcon: true,
  canEdit: false,
  uploading: false,
  model: null,
  modelId: null,
  maxMBSize: 100,
  multiple: false,
});

const emit = defineEmits<{
  (event: 'documentUploaded', arg: DocumentResource | UploadCareResponse): void;
  (event: 'update:uploading', arg: boolean): void;
}>();

const toast = useToast();

const dragOver = ref(false);
const loading = ref(false);
const dropElement = ref<HTMLElement | null>(null);

const openDialog = async () => {
  loading.value = true;
  emit('update:uploading', true);

  const files = await uploadFile({
    modelType: props.model,
    modelId: props.modelId,
    folderId: props.folderId,
    maxUploadSize: props.maxMBSize,
    multiple: props.multiple,
  });

  if (!files) {
    emit('update:uploading', false);
    loading.value = false;
    return;
  }
  emit('documentUploaded', files);
  toast.success('Document Uploaded');
  loading.value = false;

  emit('update:uploading', false);
};
</script>

<template>
  <button
    ref="dropElement"
    class="py-0.5 flex items-center bg-backgroundColor-content btn-in-table btn-info w-[110px] rounded-full border border-borderColor px-3 text-sm text-textColor disabled:cursor-not-allowed disabled:bg-borderColor"
    :disabled="loading || !canEdit"
    :class="{ 'bg-success': dragOver, '': canEdit }"
    @drop="dragOver = false"
    @dragenter="dragOver = true"
    @dragleave="dragOver = false"
    @click="openDialog">
    <IconWithLoading
      classes="mr-3"
      :icon="withIcon ? 'fa-upload fa-xs' : null"
      :loading="loading" />
    <template v-if="loading"> Uploading...</template>
    <template v-else> Upload file</template>
  </button>
</template>
