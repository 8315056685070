<script setup lang="ts">
import { ShiftTypeResource } from '@/types/shifts';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { arrayToJoinString, getItemFromArrayBasedOnId } from '@/util/globals';
import { formatStampAsDateTime, formatStampAsHumanReadableDate, formatStampAsTime } from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  shift: {
    isAssigned: boolean;
    userId: number | null;
    user_id: number | null;
    shift_id: number;
    start: string;
    end: string;
    shift_type_id: string;
    events: any[];
  };
  shiftTypes: ShiftTypeResource[];
  viaGroupName?: string;
  otherUsersInGroup: any;
  shiftsOnDay: any;
  eventsOfUsersOnDay: any;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'replacementDone', ...args: any[]): void;
}>();

const toast = useToast();
const { assertCertain } = useCertaintyModal();

const working = ref(false);
const assignToUserId = ref<number | null>(null);

const canReplace = computed(() => assignToUserId.value !== null);

const hasShiftInSlot = (user) => {
  return _.findIndex(props.shiftsOnDay, (s) => s.user_id === user.model_id) > -1;
};

const hasEventInSlot = (user) => {
  return _.findIndex(props.eventsOfUsersOnDay, (s) => s.user_id === user.model_id) > -1;
};

const reassignToUser = async (user: any, close: () => void) => {
  if (user.model !== 'user') return;
  if (working.value) return;
  if (!props.shift.isAssigned) {
    await assignToUser(user, close);
    return;
  }
  if (user.model_id === props.shift.userId) {
    toast.warning('Cannot replace with same person');
    return;
  }
  if (hasShiftInSlot(user) || hasEventInSlot(user)) {
    const certain = await assertCertain(
      'Replace shift',
      `Are you certain you want to replace the shift with ${user.title}?`
    );
    if (!certain) return;
  }

  working.value = true;

  await axios
    .post(`/api/shifts/${props.shift.shift_id}/replace`, {
      user_id: user.model_id,
    })
    .catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });
  useToast().success('Shift replaced');
  working.value = false;
  emit('replacementDone', 'Replaced.', props.shift.shift_id, user.model_id);
  close();
};

const getCurrentUser = () => {
  const userId = props.shift.hasOwnProperty('userId') ? props.shift.userId : props.shift.user_id;
  if (userId !== null) {
    const index = _.findIndex(props.otherUsersInGroup, (s) => s.model_id === userId);
    if (index > -1) {
      return props.otherUsersInGroup[index].title;
    }
  }
  return 'Not assigned';
};

const assignToUser = async (user: any, close: () => void) => {
  if (user.model !== 'user') return;
  if (working.value) return;
  if (props.shift.isAssigned) {
    await reassignToUser(user, close);
    return;
  }

  if (hasShiftInSlot(user) || hasEventInSlot(user)) {
    const certain = await assertCertain(
      'Replace shift',
      `Are you certain you want to replace the shift with ${user.title}?`
    );
    if (!certain) return;
  }

  working.value = true;
  await axios
    .patch(`/api/shifts/${props.shift.shift_id}`, {
      user_id: user.model_id,
    })
    .catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });

  useToast().success('Shift Assigned');
  working.value = false;
  emit('replacementDone', 'Assigned.', props.shift.shift_id, user.model_id);
  close();
};

const getTitleForUser = (user) => {
  const shifts = props.shiftsOnDay.filter((s) => s.user_id === user.model_id);
  const events = _.uniqBy(
    props.eventsOfUsersOnDay.filter((s) => s.user_id === user.model_id),
    'id'
  );
  if (shifts.length === 0 && events.length === 0) {
    return `${user.title} is available`;
  }
  let string = `${user.title} `;

  if (shifts.length > 0) {
    string = `${string} has ${shifts.length} shifts in period (${arrayToJoinString(
      shifts.map((s) => `${s.title}: ${formatStampAsDateTime(s.start)} - ${formatStampAsDateTime(s.end)}`)
    )})`;
  }
  if (events.length > 0) {
    string = `${string} has ${events.length} events in period (${arrayToJoinString(
      events.map((s) => `${s.title}: ${formatStampAsDateTime(s.start)} - ${formatStampAsDateTime(s.end)}`)
    )})`;
  }

  return string;
};
</script>

<template>
  <CrudModal
    v-slot="{ close }"
    :loading="working"
    :disabled="working || !canReplace"
    :title="(shift.isAssigned ? 'Find Replacement for' : 'Assign') + ' Shift'"
    :only-close-button="true"
    @closed="$emit('closed')">
    <div class="grid grid-cols-[150px_auto] gap-5 pb-4">
      <div class="pt-[6px]">
        <div class="flex flex-col gap-5">
          <div>
            <InputLabel
              super-text
              label="Date" />
            {{ formatStampAsHumanReadableDate(shift.start) }}
          </div>
          <div>
            <InputLabel
              super-text
              label="time" />
            {{ formatStampAsTime(shift.start) }} - {{ formatStampAsTime(shift.end) }}
          </div>
          <div>
            <InputLabel
              super-text
              label="Group" />
            {{ viaGroupName }}
          </div>
          <div v-if="shiftTypes.length > 0">
            <InputLabel
              super-text
              label="Type" />
            {{ getItemFromArrayBasedOnId(shift.shift_type_id, shiftTypes, { title: 'N/A' }).title }}
          </div>
          <div>
            <InputLabel
              super-text
              label="Events" />
            {{
              shift.events.length === 0
                ? 'N/A'
                : arrayToJoinString(
                    shift.events.map(function (e) {
                      return e.name;
                    })
                  )
            }}
          </div>

          <div>
            <InputLabel
              super-text
              label="Currently Assigned" />
            {{ getCurrentUser() }}
          </div>
        </div>
      </div>
      <div>
        <h3>Available Users</h3>
        <div class="max-h-[60vh] overflow-auto pt-1">
          <VTable
            edge-to-edge
            sticky-header
            row-size="tiny">
            <VTableRow
              v-for="user in otherUsersInGroup"
              :key="user.id"
              :title="getTitleForUser(user)">
              <VTableCell>
                <i
                  :class="
                    'fa fa-fw fa-circle ' +
                    (hasShiftInSlot(user) || hasEventInSlot(user) ? 'text-warning' : 'text-highlight')
                  " />
              </VTableCell>
              <VTableCell
                classes=""
                :class="hasShiftInSlot(user) || hasEventInSlot(user) ? 'opacity-50' : ''"
                :style="user.model_id === shift.userId ? 'text-decoration: line-through; ' : ''">
                <div class="w-[200px] truncate">
                  {{ user.title }}
                </div>
              </VTableCell>
              <template v-if="shift.isAssigned">
                <VTableCell v-if="user.model_id !== shift.userId">
                  <VButton
                    size="inTable"
                    title="Replace"
                    @click="reassignToUser(user, close)"></VButton>
                </VTableCell>
                <VTableCell
                  v-else
                  main-cell>
                  <small class="uppercase"><i>Assigned</i></small>
                </VTableCell>
              </template>
              <template v-else>
                <VTableCell>
                  <VButton
                    size="inTable"
                    title="Assign"
                    @click="assignToUser(user, close)" />
                </VTableCell>
              </template>
            </VTableRow>
          </VTable>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
