import { RunningorderResource, RunningOrderRowColumn, RunningOrderRowResource } from '@/types/runningorder';
import { createDateTime, toBackendTimeSecondFormat } from '@/util/date';
import { createUuId, getIndexFromArrayBasedOnId } from '@/util/globals';
import { changeAndFormatStamp, getNow } from '@/util/timeFunctions';

export const columnTypes = [
  {
    title: 'Text',
    component: 'runningorder-column-text',
    width: 'width-regular',
    class: 'fa fa-file-text-o',
  },
  {
    title: 'Document',
    component: 'runningorder-column-document',
    width: 'width-regular',
    class: 'fa fa-file-o',
  },
  {
    title: 'Image',
    component: 'runningorder-column-image',
    width: 'width-small',
    class: 'fa fa-image',
  },
] as const;

export const whisperStringBlink30Seconds = 'blink_for_30_seconds' as const;
export const whisperStringBlankOut = 'black_out_stage_timer' as const;

export const createExampleRunningOrder = (st: string) => {
  let startTime = st;
  if (createDateTime(startTime).isValid) {
    startTime = toBackendTimeSecondFormat(startTime);
  }
  const columnDefinitions: RunningOrderRowColumn[] = [];
  const rows: RunningOrderRowResource[] = [];
  const soundId = createUuId('column_');
  const lightId = createUuId('column_');

  columnDefinitions.push({
    component: 'runningorder-column-text',
    options: null,
    title: 'Sound',
    id: soundId,
    width: 'width-regular',
    restrict_edit: false,
    value: null,
  });

  columnDefinitions.push({
    component: 'runningorder-column-text',
    options: null,
    title: 'Light',
    id: lightId,
    width: 'width-regular',
    restrict_edit: false,
    value: null,
  });

  rows.push({
    title: 'Example Row 1',
    start: startTime,
    end: startTime,
    color: 'color-blue',
    id: createUuId('row_'),
    columns: columnDefinitions,
    duration: '00:00:00',
    auto_next: false,
    no_stage_timer: false,
    start_on_time: false,
  });

  rows.push({
    title: 'Example Row 2',
    start: startTime,
    duration: '00:00:00',
    end: startTime,
    color: 'color-green',
    id: createUuId('row_'),
    columns: columnDefinitions,
    auto_next: false,
    no_stage_timer: false,
    start_on_time: false,
  });

  rows.push({
    title: 'Example Row 3',
    start: startTime,
    duration: '00:00:00',
    end: startTime,
    color: 'color-brown',
    id: createUuId('row_'),
    columns: columnDefinitions,
    auto_next: false,
    no_stage_timer: false,
    start_on_time: false,
  });

  return rows;
};

export const getStartOfRow = (
  row: RunningOrderRowResource,
  rows: RunningOrderRowResource[],
  runningorder: RunningorderResource
) => {
  const indexOfThisRow = getIndexFromArrayBasedOnId(row.id, rows);
  let sum = 0;
  for (let i = 0; i < indexOfThisRow; i++) {
    sum += rows[i].duration;
  }
  let stamp = runningorder.start;
  if (runningorder.template_start) {
    const split = runningorder.template_start.split(':');
    stamp = changeAndFormatStamp({
      stamp: getNow(),
      startOf: 'day',
      addMinutes: Number(split[0]) * 60 + Number(split[1]),
    });
  }
  return changeAndFormatStamp({
    stamp: stamp,
    addSeconds: sum,
  });
};

export const whisperInShowMode = (
  runningOrderId: number,
  string: string | null,
  data: object | null | string | boolean = null
) => {
  Echo.join(`Runningorder.Showmode.${runningOrderId}`).whisper(string, data);
};
