<script setup lang="ts">
import { getCheckListRow, patchCheckListRows, postCheckListRows } from '@/services/api-check-lists';
import { CheckListResource, CheckListRowResource } from '@/types/check-list';
import { GroupResource } from '@/types/group';
import { usePage } from '@inertiajs/vue3';
import { computed, inject, ref } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import CheckListRow from '@/components/Models/CheckLists/CheckListRow.vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, tooShortOrLong } from '@/util/globals';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { getNow } from '@/util/timeFunctions';
import VButton from '@/components/Inputs/VButton.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { eventTypesKey } from '@/provide/keys';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  canEdit: boolean;
  isTemplate: boolean;
  isDisplay: boolean;
  group?: GroupResource;
};

const props = defineProps<Props>();

defineEmits<{
  'edit': [];
}>();

const toast = useToast();
const { assertCertain } = useCertaintyModal();

const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const checkList = defineModel<CheckListResource>();

const markingAsDone = ref(false);
const open = ref(false);
const editMode = ref(false);

const updateRowOrder = async ({ item, order }: { item: CheckListRowResource; order: number }) => {
  await patchCheckListRows(item.id, { order });
  toast.success('Updated');
};

const removeRow = async (rowId: number) => {
  if (!checkList.value) return;

  const index = getIndexFromArrayBasedOnId(rowId, checkList.value.rows);
  if (index > -1) {
    checkList.value.rows.splice(index, 1);
  }
};

const fetchCheckListRow = async (id: number) => {
  if (!checkList.value) return;

  const { data } = await getCheckListRow(id);

  checkList.value.rows = exchangeValuesOfObject(data, checkList.value.rows);
};

const newRowTitle = ref('');
const loadingNewRow = ref(false);

const addRow = async () => {
  if (!checkList.value || loadingNewRow.value) return;

  if (tooShortOrLong(newRowTitle.value, 'Title')) return;

  loadingNewRow.value = true;

  let order = 100;
  if (checkList.value.rows.length > 0) {
    const item = checkList.value.rows[checkList.value.rows.length - 1];
    if (item.order) {
      order = item.order + 100;
    }
  }

  const { data } = await postCheckListRows(newRowTitle.value, checkList.value.id, order);

  newRowTitle.value = '';

  checkList.value.rows.push(data);

  toast.success('Added');

  loadingNewRow.value = false;
};

const markCheckListAsDone = async () => {
  if (props.isTemplate || !checkList.value) return;

  if (markingAsDone.value) return;

  const certain = await assertCertain(
    'Mark as done',
    'Are you sure that all inputs has been added to this check list? After locking it, no changes can be done.'
  );
  if (!certain) return;

  markingAsDone.value = true;

  await axios.patch(`/api/check-lists/${checkList.value.id}/mark-as-done`);

  markingAsDone.value = false;

  toast.success('Check List marked as done');

  checkList.value.locked = true;
  checkList.value.locked_date = getNow();
  checkList.value.locked_id = usePage().props.auth.user.id;
  checkList.value.lockedBy = usePage().props.auth.user.name;
};

const connectedGroups = computed(() => {
  if (!props.isTemplate || !checkList.value) return '';

  if (props.group && props.group.childGroups) {
    if (checkList.value.groups.length === 0) return 'N/A';
    return props.group.childGroups.map((group) => group.name).join(', ');
  }

  return '';
});

const unmarkAsDone = async () => {
  if (!checkList.value) return;

  const certain = await assertCertain(
    'Unmark as done',
    'Are you sure that you want to unmark this check list as done?'
  );

  if (!certain) return;

  downloading.value = true;
  await axios.patch(`/api/check-lists/${checkList.value.id}`, {
    title: checkList.value.title,
    description: checkList.value.description,
    start: checkList.value.start,
    locked: false,
  });
  toast.success('Check List Opened');

  checkList.value.locked = false;
  checkList.value.locked_date = null;
  checkList.value.locked_id = null;
  checkList.value.lockedBy = null;

  downloading.value = false;
};

const downloading = ref(false);

const download = async () => {
  if (downloading.value || !checkList.value) return;

  downloading.value = true;

  try {
    const response = await axios.post('/api/check-lists/event-report', {
      downloadableCheckLists: [checkList.value.id],
    });
    if (response.status === 204) {
      toast.warning('There are no data in the requested check list.');
      downloading.value = false;
      return;
    }
    if (response.status === 205) {
      toast.warning('Can only download check lists for events.');
      downloading.value = false;
      return;
    }
    await downloadFile(response.data.url, response.data.name);
    downloading.value = false;
  } catch (e) {
    downloading.value = false;
    toast.warning('Something went wrong, please try again later');
    throw e;
  }
};

const actions = computed(() => {
  const array = [];
  if (props.isTemplate || editMode.value) {
    array.push({
      title: editMode.value ? 'Done' : 'Edit Rows',
      icon: editMode.value ? 'fa-circle-check' : 'fa-pen',
      action: () => {
        editMode.value = !editMode.value;
      },
    });
  }

  if (!props.isTemplate && checkList.value) {
    const items = [];
    if (!checkList.value.locked && props.canEdit) {
      items.push({
        title: 'Edit Rows',
        icon: 'fa-pencil',
        action: () => {
          editMode.value = true;
        },
      });
    }
    items.push({
      title: 'Download',
      icon: 'fa-download',
      action: () => {
        download();
      },
    });

    if (checkList.value && checkList.value.locked && props.canEdit) {
      items.push({
        title: 'Open Check List',
        icon: 'fa-square-o',
        action: () => {
          unmarkAsDone();
        },
      });
    }
    array.push({
      icon: 'fa-chevron-down',
      loading: downloading.value,
      dropdown: items,
    });
  }

  return array;
});

const connectetedEventTypes = computed(() => {
  if (!checkList.value) return [];

  return eventTypes.value.filter((ev) => ev.pivot.check_lists.some((cl) => cl.id === checkList.value?.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});
</script>

<template>
  <ContentContainer
    v-if="checkList"
    :can-edit="canEdit"
    :edit-mode="isTemplate ? true : canEdit && !checkList.locked"
    :title="checkList.title"
    :just-content-without-header="isDisplay"
    :actions="actions"
    pre-icon="fa-list-check"
    :extra-height="isTemplate || isDisplay ? 0 : 70"
    @edit="checkList.locked ? null : $emit('edit')"
    @open="open = $event">
    <template #title>
      <div v-if="checkList">
        <div class="header flex items-center gap-3">
          <h3>
            {{ checkList.title }}
          </h3>
          <i
            v-if="checkList.locked"
            class="fa fa-fw fa-check-square-o"
            :title="
              'Check list has been marked as done by ' + checkList.lockedBy + ' at ' + checkList.locked_date + '.'
            " />
        </div>
        <div class="flex gap-10">
          <div
            v-if="checkList.start"
            class="ml-15 !sub-title">
            {{ checkList.start }}
          </div>
          <div v-if="!isTemplate">
            <div
              v-if="checkList.locked && checkList.locked_date && checkList.lockedBy"
              class="!sub-title text-textColor-soft">
              Done by <span class="text-highlight">{{ checkList?.lockedBy }}</span> at
              <span class="text-highlight"> {{ checkList.locked_date }} </span>
            </div>
            <div
              v-else-if="!open && !checkList.locked"
              class="!sub-title text-pending">
              Pending
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #underHeader>
      <div
        v-if="open"
        class="flex flex-col gap-2"
        :class="open ? '  pl-edge ' : ''">
        <div class="text-textColor-soft">
          {{ checkList.description }}
        </div>
        <div v-if="isTemplate && group && group.childGroups.length > 0">
          <span class="text-textColor-soft">Shared with:</span>
          {{ connectedGroups }}
        </div>
      </div>

      <div
        v-if="!open && !isTemplate"
        class="text-textColor-soft grid grid-cols-3"></div>
    </template>
    <template #content>
      <VTable
        v-if="checkList"
        v-model="checkList.rows"
        edge-to-edge
        bordered-table
        sticky-header
        :can-drag="editMode"
        row-size="medium"
        @new-order="updateRowOrder">
        <template #head>
          <VTableRow head>
            <VTableCell
              v-if="(isTemplate && canEdit) || (editMode && canEdit && checkList && !checkList.locked)"
              style="width: 50px" />
            <VTableCell> Title</VTableCell>
            <template v-if="!isTemplate && !editMode">
              <VTableCell style="width: 50px"> Done</VTableCell>
              <VTableCell> Notes</VTableCell>
              <VTableCell style="width: 35px"> User</VTableCell>
            </template>
            <VTableCell v-if="(isTemplate && editMode) || (canEdit && !checkList.locked && editMode)" />
          </VTableRow>
        </template>

        <template #row="{ item }">
          <CheckListRow
            :row="item"
            :is-template="isTemplate"
            :locked="checkList?.locked || isDisplay"
            :can-edit="canEdit"
            :edit-mode="editMode"
            @deleted="removeRow(item.id)"
            @updated="fetchCheckListRow(item.id)" />
        </template>

        <template #footer>
          <VTableRow
            v-if="canEdit && !checkList?.locked && editMode"
            class="check-list-new-row dark-input-field">
            <VTableCell colspan="100%">
              <div class="px-edge">
                <TextInput
                  v-model="newRowTitle"
                  placeholder="Description of new Row"
                  action-buttons
                  save-action-title="Add"
                  with-save-action
                  :disabled="loadingNewRow"
                  :with-clear-action="false"
                  @keyup.enter="addRow"
                  @clear="newRowTitle = ''"
                  @save="addRow" />
              </div>
            </VTableCell>
          </VTableRow>
        </template>
      </VTable>
      <div class="mt-5 text-center">
        <VButton
          v-if="!isTemplate && checkList?.rows?.length && !checkList.locked && !isDisplay"
          type="success"
          :loading="markingAsDone"
          :disabled="markingAsDone"
          icon="fa-check-square-o"
          title="Mark as done"
          @click="markCheckListAsDone" />
      </div>
    </template>
  </ContentContainer>
</template>
