<script lang="ts" setup>
import { ZIndexDropDown } from '@/variables/z-indexes';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { Fn, onClickOutside, useEventListener } from '@vueuse/core';
import { createUuId } from '@/util/globals';
import { useEmitStore } from '@/store/EmitStore';
import VList, { type ListItem } from '@/components/VList.vue';
import { twMerge } from 'tailwind-merge';

export type DropDownListItem = ListItem & {
  action?: (close: () => void) => void;
};

type Props = {
  buttonTitle?: string | null;
  canOpenDropdown?: boolean;
  items?: DropDownListItem[];
  itemKey?: string;
  itemLabel?: string;
  tabindex?: number | null;
  closeOnClick?: boolean;
  setFocus?: boolean;
  zIndexOfDropdown?: number;
  maxHeightDropdown?: string;
  wrapperClasses?: string | null;
  pageXModifier?: number;
  pageYModifier?: number;
  haveMaxWidth?: boolean;
  targetClasses?: string | null;
  withFlexOne?: boolean;
  closeOnScroll?: boolean;
  closeOnEsc?: boolean;
  shouldClose?: boolean;
  openDropdownFromOutside?: boolean;
  highlightText?: string | null;
  withArrowsUpAndDown?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  buttonTitle: null,
  canOpenDropdown: true,
  items: () => [],
  itemKey: 'id',
  itemLabel: 'name',
  closeOnClick: false,
  setFocus: false,
  zIndexOfDropdown: ZIndexDropDown,
  maxHeightDropdown: '40vh',
  wrapperClasses: null,
  targetClasses: null,
  tabindex: undefined,
  tabindex: null,
  pageYModifier: 0,
  pageXModifier: 0,
  haveMaxWidth: true,
  withFlexOne: true,
  closeOnScroll: true,
  closeOnEsc: true,
  shouldClose: true,
  openDropdownFromOutside: true,
  highlightText: null,
  withArrowsUpAndDown: false,
});
const emit = defineEmits<{
  (e: 'itemClicked', arg: any, arg2: any): void;
  (e: 'dropdownOpened'): void;
  (e: 'dropdownClosed'): void;
}>();

const uuid = createUuId();

const show = defineModel<boolean>({ default: false });
const wrapper = ref<HTMLDivElement | null>(null);
const target = ref<HTMLDivElement | null>(null);

const top = ref(0);
const left = ref(0);
// const minWidth = ref(100);

const calculatedMaxHeightDropdown = ref(props.maxHeightDropdown);

const calcHeight = (numberOfItems: number, itemsHeight = 29) => {
  let height = numberOfItems * itemsHeight;
  height = height + 16;
  if (props.maxHeightDropdown.includes('vh')) {
    const vh = window.innerHeight;
    const percent = props.maxHeightDropdown.replace('vh', '');
    const px = (vh * parseInt(percent, 10)) / 100;

    if (px < height) {
      calcHeight(numberOfItems - 1);
    } else {
      calculatedMaxHeightDropdown.value = `${height}px`;
    }
  } else {
    const px = props.maxHeightDropdown.replace('px', '');
    if (parseInt(px, 10) < height) {
      calcHeight(numberOfItems - 1);
    } else {
      calculatedMaxHeightDropdown.value = `${height}px`;
    }
  }
};

onMounted(async () => {
  await nextTick();
  if (props.items?.length) {
    calcHeight(props.items.length);
  }
});

watch(show, () => {
  if (show.value) {
    setTimeout(() => {
      const liItems = target.value?.querySelectorAll('li');
      const first = liItems?.[0];
      const height = first?.clientHeight;
      if (props.items?.length) {
        calcHeight(props.items.length, height);
      }
    }, 50);
  }
});

watch(
  () => props.items,
  () => {
    if (props.items?.length) {
      calcHeight(props.items.length);
    }
  }
);

onClickOutside(target, (event) => {
  if (!props.shouldClose) return;
  if (!event.composedPath()?.some((item) => item.id === uuid)) {
    setTimeout(() => {
      show.value = false;
    }, 100);
  }
});

const onClick = async () => {
  if (props.canOpenDropdown && wrapper.value) {
    show.value = !show.value;
    await nextTick();
    let overflow = 0;
    const wrapperRect = wrapper.value?.getBoundingClientRect();

    let dropdownRect;
    if (target.value) {
      dropdownRect = target.value?.getBoundingClientRect();
      if (document.body.clientWidth > dropdownRect?.width) {
        overflow = wrapperRect.left + dropdownRect.width - document.body.clientWidth;
      }
    }
    left.value = wrapperRect.left + props.pageXModifier;

    if (overflow > 0) {
      left.value -= overflow + 5;
    }

    if (document.body.clientWidth <= (dropdownRect?.width ?? 0)) {
      left.value = 0;
    }

    top.value = wrapperRect.bottom + window.scrollY + 5 + props.pageYModifier;

    await nextTick();

    if (target.value) {
      const dropdownRect = target.value?.getBoundingClientRect();
      if (dropdownRect && dropdownRect.bottom > window.innerHeight) {
        top.value = wrapperRect.top + window.scrollY - dropdownRect.height - 5 + props.pageYModifier;
      }
    }

    // minWidth.value = wrapperRect.width;
    if (show.value) {
      emit('dropdownOpened');
    } else {
      emit('dropdownClosed');
    }
  }
};

const closeAll = () => {
  show.value = false;
  emit('dropdownClosed');
};

const closeDropdown = (item: unknown | null, event: MouseEvent | null, force = false) => {
  emit('itemClicked', item, event);

  if (item && typeof item === 'object' && 'action' in item && typeof item.action === 'function') {
    item.action(event);
  }
  if (!props.closeOnClick && !force) return;

  show.value = false;
  emit('dropdownClosed');
};

const scrollLisetener = ref<Fn | null>(null);

watch(
  () => props.closeOnScroll,
  (newValue) => {
    if (newValue && show.value) {
      scrollLisetener.value = useEventListener(
        'wheel',
        (event) => {
          if (!target.value?.contains(event.target as Node)) {
            show.value = false;
            emit('dropdownClosed');
          }
        },
        { passive: true }
      );
    } else {
      scrollLisetener.value?.();
    }
  }
);

watch(show, () => {
  if (!show.value) {
    scrollLisetener.value?.();
    emit('dropdownClosed');
  }
  if (show.value && props.closeOnScroll) {
    scrollLisetener.value = useEventListener(
      'wheel',
      (event) => {
        if (!target.value?.contains(event.target as Node)) {
          show.value = false;
          emit('dropdownClosed');
        }
      },
      { passive: true }
    );
  }
});
const handleTab = async (event) => {
  if (event.key === 'Escape' && show.value) {
    show.value = false;
    return;
  }
  setTimeout(async () => {
    if (event.keyCode !== 9) return;
    if (show.value) {
      show.value = false;
      return;
    }
    if (document.activeElement === wrapper.value) {
      event.preventDefault();
      await nextTick();
      await onClick();
    }
  }, 10);
};

onBeforeUnmount(async () => {
  document.removeEventListener('keydown', handleTab);
});

onMounted(async () => {
  await nextTick();
  if (props.setFocus) {
    await onClick();
  }
  document.addEventListener('keydown', handleTab);
});

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'close-all-drop-downs': {
      show.value = false;
      break;
    }
    default:
      break;
  }
});
watch(
  () => props.openDropdownFromOutside,
  () => {
    if (props.openDropdownFromOutside) {
      onClick();
    }
  }
);
</script>

<template>
  <div
    :id="uuid"
    ref="wrapper"
    :tabindex="tabindex"
    class="relative"
    :class="[wrapperClasses + (canOpenDropdown ? ' cursor-pointer ' : ''), { 'flex-1': withFlexOne }]"
    @click.stop="onClick">
    <slot
      name="click-area"
      :close="closeDropdown"
      :open="onClick"
      :state="show"
      :is-open="show">
      <button class="btn-primary">
        {{ buttonTitle }}
      </button>
    </slot>

    <teleport to="body">
      <div
        v-if="show"
        ref="target"
        :style="`left: ${left}px; top: ${top}px; z-index: ${zIndexOfDropdown}`"
        :class="
          twMerge(
            'absolute min-h-[20px] min-w-[200px] overflow-hidden rounded border-2 shadow',
            haveMaxWidth && 'max-w-[500px]',
            targetClasses
          )
        ">
        <slot name="aboveDropdown" />
        <div
          class="overflow-y-auto overflow-x-hidden rounded-md bg-backgroundColor-content ring-1 ring-borderColor"
          :style="`max-height: ${calculatedMaxHeightDropdown};`">
          <slot
            :close="closeDropdown"
            name="dropdown">
            <div class="dropdown-container">
              <VList
                v-if="items?.length"
                :highlight-text="highlightText"
                :with-arrows-up-and-down="withArrowsUpAndDown"
                :items="
                  items.map((item) => ({
                    ...item,
                    items:
                      item?.items?.map((subItem) => ({
                        ...subItem,
                        action: subItem.action
                          ? () => {
                              subItem.action(closeAll);
                            }
                          : undefined,
                      })) ?? [],
                    action: item.action
                      ? () => {
                          item.action(closeAll);
                        }
                      : undefined,
                    postIcon:
                      typeof item.postIcon === 'object'
                        ? {
                            ...item.postIcon,
                            action: () => {
                              item.postIcon?.action(closeAll());
                            },
                          }
                        : item.postIcon,
                  }))
                ">
                <template
                  v-if="$slots.pre"
                  #pre="{ item }">
                  <slot
                    v-if="item.type !== 'header'"
                    name="pre"
                    :item="item" />
                </template>
              </VList>
            </div>
          </slot>
        </div>
      </div>
    </teleport>
  </div>
</template>
