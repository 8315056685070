<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    type?: Type;
    size?: Size;
    disabled?: boolean;
    loading?: boolean;
    error?: boolean;
    icon?: string | null;
    title?: string | null;
    toolTipText?: string;
    disabledToolTipText?: string;
  }>(),
  {
    type: 'primary',
    size: 'medium',
    disabled: false,
    loading: false,
    error: false,
    icon: null,
    title: '',
    toolTipText: '',
    disabledToolTipText: '',
  }
);

defineEmits<{
  (e: 'click', arg: MouseEvent): void;
}>();

const button = cva('btn focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2', {
  variants: {
    type: {
      primary: 'focus-visible:outline-white/50',
      success: 'btn-success focus-visible:outline-success',
      pending: 'btn-pending focus-visible:outline-pending',
      info: 'btn-info',
      warning: 'btn-warning focus-visible:outline-warning',
      hidden: 'btn-hidden focus-visible:outline-white/50',
      inTable: 'btn-in-table focus-visible:outline-white/50',

      // To be removed
      'btn-in-table': 'btn-in-table',
    },
    size: {
      'extra-small': 'btn-xs',
      tiny: 'btn-tiny',
      medium: '',
      small: 'btn-sm',
      large: 'btn-lg',
      inTable: 'btn-in-table',

      // To be removed
      sm: 'btn-sm',
    },
  },
  defaultVariants: {
    type: 'primary',
    size: 'medium',
  },
});

type Type = 'primary' | 'success' | 'pending' | 'warning' | 'hidden' | 'inTable' | 'info';
type Size = 'extra-small' | 'medium' | 'small' | 'large' | 'inTable' | 'tiny';

const tooltip = computed(() => {
  if (props.loading) return 'loading';
  if (props.disabled && props.disabledToolTipText) return props.disabledToolTipText;
  return props.toolTipText;
});

const btn = ref<HTMLButtonElement | null>();

defineExpose({
  button: btn,
});
</script>

<template>
  <button
    ref="btn"
    :class="button({ type, size })"
    class="relative focus:outline-none"
    :title="tooltip"
    type="button"
    :disabled="disabled || loading"
    @click.stop="$emit('click', $event)">
    <span :class="loading && !icon ? 'invisible' : 'visible'">
      <slot>
        <i
          v-if="icon && !loading"
          :class="[icon, { 'mr-1': !!title }]"
          class="fa fa-fw" />
        <i
          v-else-if="icon && loading"
          :class="{ 'mr-1': !!title }"
          class="fa fa-fw fa-circle-o-notch fa-spin" />
        {{ title ? title : '' }}
      </slot>
    </span>

    <span
      v-show="loading && !icon"
      class="absolute left-0 top-0 flex h-full w-full items-center justify-center">
      <i class="fa fa-fw fa-circle-o-notch fa-spin" />
    </span>
  </button>
</template>
