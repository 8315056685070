import { MetaDataFieldSharedWithGroupResource } from '@/types/meta-data';
import type { MetaDataResource, MetaDataModelType, MetaDataFieldResource } from '@/types/meta-data';

export const getMetaDataList = async (modelType: MetaDataModelType, modelId: number) => {
  const url = '/api/meta-data';
  return axios.get<MetaDataResource[]>(url, {
    params: {
      model_type: 'App\\' + modelType,
      model_id: modelId,
    },
  });
};

export const postMetaData = async (modelType: MetaDataModelType, modelId: number, title: string) => {
  const url = '/api/meta-data';
  return axios.post<MetaDataResource>(url, {
    model_type: 'App\\' + modelType,
    model_id: modelId,
    title,
  });
};

export const patchMetaData = async (id: number, title: string, showTimeId: number | null) => {
  const url = `/api/meta-data/${id}`;
  return axios.patch<string>(url, {
    title,
    show_time_id: showTimeId,
  });
};

export const destroyMetaData = async (id: number) => {
  const url = `/api/meta-data/${id}`;
  return axios.delete<string>(url);
};

export const getMetaData = async (id: number) => {
  return axios.get<MetaDataResource>(`/api/meta-data/${id}`);
};

export const getMetaDataFields = async (id: number) => {
  return axios.get<MetaDataFieldResource[]>(`/api/meta-data-fields/${id}`);
};

export const getMetaDataFieldShare = async (id: number) => {
  return axios.get<MetaDataFieldSharedWithGroupResource[]>(`/api/meta-data-fields/${id}/share`);
};

type CreateMetaDataField = {
  component?: string;
  class?: string;
  options?: string[] | null;
  meta_data_id?: number;
  title?: string;
  linebreak_after?: boolean;
  order?: number;
};

export const postMetaDataField = async (data: CreateMetaDataField) => {
  return axios.post<MetaDataFieldResource>('/api/meta-data-fields', data);
};

export const patchMetaDataField = async (id: number, data: Omit<CreateMetaDataField, 'meta_data_id' | 'component'>) => {
  return axios.patch<string>(`/api/meta-data-fields/${id}`, data);
};

export const destroyMetaDataField = async (id: number) => {
  return axios.post<string>(`/api/meta-data-fields/${id}/delete`);
};

export const shareMetaDataField = async (fieldId: number, groupId: number, write: boolean) => {
  const url = `/api/meta-data-fields/${fieldId}/share`;
  return axios.post<string>(url, {
    group_id: groupId,
    write,
  });
};

export const unshareMetaDataField = async (id: number, groupId: number) => {
  const url = `/api/meta-data-fields/${id}/share`;
  return axios.delete<string>(url, {
    params: {
      group_id: groupId,
    },
  });
};
