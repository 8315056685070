<script lang="ts" setup>
import type { Action } from '@/components/Inputs/Components/ActionButtons.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useSmallScreen } from '@/composables/use-small-screen';
import { slotEmpty } from '@/util/globals';
import { openRoute } from '@/util/route.js';
import { Link } from '@inertiajs/vue3';
import { gsap } from 'gsap';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import SlideoutMenuButton from '@/components/Base/SlideoutMenuButton.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

export type MenuItem = {
  id: number;
  icon: string;
  title: string;
};

type Props = {
  modelValue: boolean;
  halfOpen?: boolean;
  slideRight?: boolean;
  baseZIndex?: number;
  mainContentClasses?: string | null;
  menu?: MenuItem[];
  bottomButtonIcon?: string;
  activeTab?: string | number | null;
  tiny?: boolean;
  small?: boolean;
  medium?: boolean;
  withinSame?: boolean;
  warningBeforeClose?: boolean;
  footerLinkText?: string;
  footerClasses?: string;
  footerLinkUrl?: string;
  headerText?: string;
  headerLink?: string;
  headerClasses?: string;
  underHeaderText?: string;
  headerActions?: Action[];
};
const props = withDefaults(defineProps<Props>(), {
  halfOpen: false,
  slideRight: false,
  baseZIndex: 100,
  menu: () => [],
  bottomButtonIcon: '',
  activeTab: null,
  mainContentClasses: null,
  tiny: false,
  small: false,
  medium: false,
  withinSame: false,
  warningBeforeClose: false,
  headerClasses: '',
  footerLinkText: '',
  footerClasses: '',
  footerLinkUrl: '',
  headerText: '',
  headerLink: '',
  underHeaderText: '',
  headerActions: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
  (event: 'closed'): void;
  (event: 'changeTab', value: number): void;
  (event: 'clickFooterTab'): void;
  (event: 'update:activeTab', value: number): void;
}>();

const tabWidth = '90px';

const { isSmallScreen } = useSmallScreen();

const isOpen = ref(!!isSmallScreen.value);
const mainContent = ref<HTMLDivElement | null>(null);
const isMounted = ref(false);

const closeSlideout = async () => {
  if (props.warningBeforeClose) {
    const yes = await useCertaintyModal().assertCertain('Are you sure you want to close ?', '');
    if (!yes) {
      return;
    }
  }
  isOpen.value = false;
  document.body.removeAttribute('style');
  if (isSmallScreen.value) {
    emit('update:modelValue', false);
    emit('closed');
    // deactivate();
    return;
  }

  let newWidth = '0';
  if (props.tiny) {
    newWidth = props.withinSame ? '250px' : '300px';
  } else if (props.small) {
    newWidth = props.withinSame ? '500px' : '600px';
  } else if (props.medium) {
    newWidth = props.withinSame ? '800px' : '900px';
  } else {
    newWidth = props.withinSame ? '1100px' : '1200px';
  }

  if (!mainContent.value) {
    emit('update:modelValue', false);
    emit('closed');
    // deactivate();
    return;
  }

  gsap.fromTo(
    mainContent.value,
    {
      width: newWidth,
    },
    {
      width: props.halfOpen ? tabWidth : '0px',
      onComplete: () => {
        emit('update:modelValue', false);
        emit('closed');
        // deactivate();
      },
      duration: 0.2,
    }
  );
};

const openSlideout = () => {
  emit('update:modelValue', true);
};

const currentWidth = ref('0px');

const containerWidth = computed(() => {
  return `calc(${currentWidth.value} - ${tabWidth})`;
});

const showOrHideContent = () => {
  if (!props.modelValue) {
    openSlideout();
  } else {
    closeSlideout();
  }
};

const onTabChange = (tabId: number) => {
  emit('update:activeTab', tabId);
  if (!isOpen.value) openSlideout();
};

onMounted(() => {
  isMounted.value = true;
});

defineOptions({
  inheritAttrs: false,
});

const myHeaderActions = computed(() => {
  return props.headerActions.map((action) => {
    return {
      ...action,
      action: () => {
        action.action(closeSlideout);
      },
    };
  });
});

if (!props.halfOpen) {
  watch(mainContent, (newVal) => {
    if (!newVal || isOpen.value || isSmallScreen.value) return;
    isOpen.value = true;
    let newWidth = '0';
    if (props.tiny) {
      newWidth = props.withinSame ? '300px' : '400px';
    } else if (props.small) {
      newWidth = props.withinSame ? '500px' : '600px';
    } else if (props.medium) {
      newWidth = props.withinSame ? '800px' : '900px';
    } else {
      newWidth = props.withinSame ? '1200px' : '1100px';
    }
    currentWidth.value = newWidth;

    gsap.fromTo(
      mainContent.value,
      { width: props.halfOpen ? tabWidth : '0px' },
      {
        width: newWidth,
        duration: 0.3,
      }
    );
  });
}

if (props.halfOpen) {
  watch(
    () => props.modelValue,
    async (newOpen) => {
      if (newOpen) {
        document.body.style.overflow = 'hidden';
        isOpen.value = true;
        if (isSmallScreen.value) return;
        await nextTick();
        let newWidth = '0';
        if (props.tiny) {
          newWidth = props.withinSame ? '300px' : '400px';
        } else if (props.small) {
          newWidth = props.withinSame ? '500px' : '600px';
        } else if (props.medium) {
          newWidth = props.withinSame ? '800px' : '900px';
        } else {
          newWidth = props.withinSame ? '1200px' : '1100px';
        }
        currentWidth.value = newWidth;
        gsap.fromTo(
          mainContent.value,
          { width: props.halfOpen ? tabWidth : '0px' },
          {
            width: newWidth,
            duration: 0.3,
          }
        );
      }
    },
    { immediate: true }
  );
}
</script>

<template>
  <teleport
    v-if="isMounted"
    :to="halfOpen ? '#right-side' : 'body'">
    <div
      v-if="!isSmallScreen && (modelValue || halfOpen)"
      :class="[halfOpen ? 'right-0' : 'fixed left-0 w-screen']"
      :style="(halfOpen ? `width:${tabWidth}; min-width:${tabWidth};` : '') + 'z-index: ' + baseZIndex"
      class="top-0 h-dynamic-screen">
      <transition
        v-if="!isSmallScreen"
        appear
        enter-active-class="transition duration-300 ease-in-out transform"
        enter-from-class="opacity-0"
        leave-active-class="transition duration-200 ease-in-out transform"
        leave-to-class="opacity-0">
        <div
          v-if="isOpen"
          :style="'z-index: ' + (baseZIndex + 1)"
          class="fixed left-0 top-0 h-full w-full bg-[--color-backdrop-modal]"
          @click="closeSlideout" />
      </transition>

      <div
        v-if="!isSmallScreen"
        ref="mainContent"
        :style="'z-index: ' + (baseZIndex + 2)"
        :class="mainContentClasses"
        class="absolute right-1 flex h-dynamic-screen max-w-[90vw] border-l border-borderColor"
        data-intro="slide-out-main">
        <div
          :style="`width:${tabWidth}; min-width:${tabWidth};`"
          class="flex flex-col items-stretch border-r border-borderColor-soft bg-sidebarMain font-headers text-sidebarTextSoft">
          <!-- Close Button -->
          <SlideoutMenuButton
            :title="isOpen ? 'Close' : 'Open'"
            :icon="isOpen ? 'fa-arrow-right' : 'fa-arrow-left'"
            @click="showOrHideContent" />

          <slot name="menuContainer">
            <!-- Slideout Menu Buttons -->
            <div class="flex flex-1 flex-col items-stretch overflow-y-auto">
              <SlideoutMenuButton
                v-for="tab in menu"
                :key="tab.id"
                :active="activeTab === tab.id && isOpen"
                :title="tab.title"
                :icon="tab.icon"
                @click="onTabChange(tab.id)" />
            </div>

            <!-- Slideout Bottom Action -->
            <div @click="openSlideout()">
              <slot name="bottomAction" />
            </div>
          </slot>
        </div>

        <div
          v-show="isOpen"
          :style="`width: ${containerWidth}`"
          class="bg-backgroundColor">
          <slot
            :close="closeSlideout"
            name="main-content">
            <div
              class="flex h-full flex-col"
              :class="headerClasses">
              <div
                v-if="$slots.header || headerText"
                class="min-h-[80px]">
                <slot name="header">
                  <div class="flex flex-col items-center justify-center pt-4 text-left [&>div]:w-full">
                    <div class="px-edge">
                      <slot name="restrictMessage" />
                    </div>
                    <slot name="overHeader" />
                    <div class="px-edge pt-3 flex justify-between">
                      <h1
                        :class="headerLink ? 'group cursor-pointer no-underline hover:underline' : ''"
                        :title="headerText"
                        class="font-headers slide-out-header-text"
                        @click="headerLink ? openRoute(headerLink) : null">
                        {{ headerText }}
                        <i
                          v-if="headerLink"
                          class="fa fa-fw fa-external-link fa-sm text-3xl invisible ml-5 group-hover:visible" />
                      </h1>
                      <ActionButtonGroup :actions="myHeaderActions" />
                    </div>
                  </div>

                  <div
                    class="[&>div]:flex [&>div]:min-h-[40px] [&>div]:items-center [&>div]:border-b [&>div]:px-edge [&>div]:text-textColor-soft">
                    <slot name="underHeader">
                      <div v-if="underHeaderText">
                        {{ underHeaderText }}
                      </div>
                    </slot>
                  </div>
                </slot>
              </div>

              <div class="flex-1 overflow-y-auto">
                <slot :close="closeSlideout" />
              </div>

              <div
                v-if="$slots.footer || $slots.footerLink || (footerLinkText && footerLinkUrl)"
                :class="footerClasses"
                class="h-context-sidebar-bottom-height border-t bg-backgroundColor-content p-1 px-edge">
                <slot name="footer">
                  <div class="flex h-full items-center justify-between">
                    <slot name="footerButtons">
                      <div />
                    </slot>

                    <slot name="footerLink">
                      <div
                        v-if="footerLinkText && footerLinkUrl"
                        class="flex justify-center">
                        <Link
                          :href="footerLinkUrl"
                          class="no-underline hover:underline">
                          <h1>
                            <span class="text-textColor">{{ footerLinkText }}</span>
                            <i class="fa fa-fw fa-external-link ml-5" />
                          </h1>
                        </Link>
                      </div>
                    </slot>
                  </div>
                </slot>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>

    <div
      v-else-if="isSmallScreen && isOpen && (modelValue || halfOpen)"
      :style="'z-index: ' + baseZIndex"
      class="fixed left-0 top-0 h-full w-screen bg-backgroundColor-content">
      <slot
        :close="closeSlideout"
        name="main-content">
        <div class="flex h-full flex-col gap-2">
          <div class="h-16">
            <slot
              name="header"
              :close="closeSlideout">
              <div class="flex h-navbar items-center justify-between border-b">
                <div class="truncate pl-edge">
                  <slot name="header-text">
                    <h1
                      :class="headerLink ? 'group cursor-pointer no-underline hover:underline' : ''"
                      class="truncate"
                      @click="headerLink ? openRoute(headerLink) : null">
                      {{ headerText }}

                      <i
                        v-if="headerLink"
                        class="fa fa-fw fa-external-link fa-sm invisible ml-5 group-hover:visible" />
                    </h1>
                  </slot>
                </div>

                <div>
                  <button
                    class="float-right mr-2 mt-2 h-9 w-9 rounded-full hover:bg-backgroundColor-content"
                    @click="closeSlideout()">
                    <i class="fa fa-fw fa-times text-3xl" />
                  </button>
                </div>
              </div>
            </slot>
            <div
              v-if="menu.length > 0 || !slotEmpty($slots, 'secondarySmallScreenSelector')"
              :class="{ 'grid-cols-2': !slotEmpty($slots, 'secondarySmallScreenSelector') }"
              class="grid gap-5 pl-edge">
              <div
                v-if="menu.length > 0"
                class="h-[40px] mt-6">
                <InputLabel
                  class="absolute left-5 top-[75px] z-[1]"
                  label="Tab"
                  super-text />
                <VSelect
                  :model-value="activeTab"
                  :options="menu"
                  option-value="title"
                  wrapper-class="[&>*>*]:!ring-0"
                  @update:model-value="onTabChange($event)" />
              </div>

              <slot name="secondarySmallScreenSelector" />
            </div>
          </div>

          <div class="flex-1 overflow-auto">
            <slot :close="closeSlideout" />
          </div>

          <div class="h-10 w-full items-center border-t bg-backgroundColor md:fixed md:bottom-0">
            <slot name="footer">
              <div class="flex h-full items-center justify-between">
                <slot name="footerButtons">
                  <div />
                </slot>

                <slot name="footerLink">
                  <div
                    v-if="footerLinkText && footerLinkUrl"
                    class="flex justify-center truncate">
                    <Link
                      :href="footerLinkUrl"
                      class="truncate no-underline hover:underline [&>h1>i]:ml-5 [&>h1]:text-textColor">
                      <h1 class="truncate">
                        {{ footerLinkText }}
                        <i class="fa fa-fw fa-external-link" />
                      </h1>
                    </Link>
                  </div>
                </slot>
              </div>
            </slot>
          </div>
        </div>
      </slot>
    </div>

    <div
      v-if="isSmallScreen && halfOpen && !modelValue"
      class="fixed right-0 top-[49px] rounded-l-2xl border bg-row-hover overflow-hidden"
      @click="openSlideout">
      <div class="p-2">
        <InputLabel
          label="Open"
          super-text />
        <div class="text-center">
          <i class="fa fa-angle-double-left"></i>
        </div>
      </div>
    </div>
  </teleport>
</template>
