export const fieldClasses = [
  { title: 'Small', value: 'col-md-3' },
  { title: 'Medium', value: 'col-md-6' },
  { title: 'Large', value: 'col-md-12' },
] as const;

export const fieldHasOptions = (field) => {
  if (!field || !field.hasOwnProperty('component')) {
    return false;
  }
  switch (field.component) {
    case 'field-list':
    case 'board-column-list': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const allAvailableFieldTypes = [
  {
    title: 'Text',
    component: 'field-text',
    icon: 'fa fa-file-text-o',
  },
  {
    title: 'Rich Text',
    component: 'field-rich-text',
    icon: 'fa fa-paragraph',
  },
  {
    title: 'Number',
    component: 'field-number',
    icon: 'fa fa-hashtag',
  },
  {
    title: 'Toggle',
    component: 'field-toggle',
    icon: 'fa fa-check-square',
  },
  {
    title: 'List',
    component: 'field-list',
    configurator: 'field-list-configurator',
    icon: 'fa fa-list',
  },
  {
    title: 'Date',
    component: 'field-date',
    icon: 'fa fa-calendar',
  },
  {
    title: 'Document',
    component: 'field-document',
    icon: 'fa fa-file',
  },
  {
    title: 'Time',
    component: 'field-time',
    icon: 'fa fa-clock-o',
  },
] as const;
