import { DocumentModelType, DocumentResource } from '@/types/document';
import uploadcare from 'uploadcare-widget';
import { addDocumentToFolder, storeDocument } from '@/services/api-documents';

const maxFileSize = (size: number) => (fileInfo) => {
  if (fileInfo.size !== null && fileInfo.size > size) {
    throw new Error('File max size');
  }
};

type UploadOptions = {
  modelType?: DocumentModelType | null;
  modelId?: number | null;
  maxUploadSize?: number;
  folderId?: number | null;
  additionalSettings?: any;
  multiple?: boolean;
};

export const uploadFile = async ({
  modelType,
  modelId,
  maxUploadSize = 100,
  folderId,
  additionalSettings = null,
  multiple = false,
}: UploadOptions) => {
  try {
    let settings = {
      validators: [maxFileSize(maxUploadSize * 1024 * 1024)],
    };
    if (additionalSettings) {
      settings = {
        ...settings,
        ...additionalSettings,
      };
    }
    const dialog = uploadcare.openDialog(null, { multiple: multiple }, settings);
    const data: UploadCareResponse = await dialog.done();

    if (multiple) {
      const files = await data.files();

      const promises = files.map((file) => {
        return file.promise();
      });

      const allFiles = await Promise.all(promises);

      if (!modelType || !modelId) {
        return allFiles;
      }

      const uploadedFiles: Promise<DocumentResource[]>[] = [];

      allFiles.map((f) => {
        const prom = storeDocument(modelType, modelId, f.uuid);
        uploadedFiles.push(prom);
      });

      const test = await Promise.all(uploadedFiles);

      if (folderId) {
        const folderPromises = [];

        test.map((t) => {
          const folder = addDocumentToFolder(modelType, modelId, folderId, t.data.id);
          folderPromises.push(folder);
        });

        await Promise.all(folderPromises);
      }

      return test.map((t) => t.data);
    } else {
      if (!modelType || !modelId) {
        return data;
      }

      const { data: file }: { data: DocumentResource } = await storeDocument(modelType, modelId, data.uuid);

      if (folderId) {
        await addDocumentToFolder(modelType, modelId, folderId, file.id);
      }

      return file;
    }
  } catch (e) {
    // useToast().error('Error uploading file');
    // throw e;
  }
  return null;
};

type UploadDropOptions = {
  el: HTMLElement;
  callback: (file: any, folder?: any) => void;
  loading: () => void;
  dropText?: string;
  modelType: string;
  modelId: number;
  folderId?: number;
  maxUploadSize?: number;
};

export const addDropArea = async ({
  el,
  callback,
  loading,
  maxUploadSize = 100,
  modelType,
  modelId,
  folderId,
}: UploadDropOptions) => {
  uploadcare.dragdrop.uploadDrop(
    el,
    async (file: any) => {
      loading();
      const fileInfo = await file;
      const { data: newFile } = await storeDocument(modelType, modelId, fileInfo.uuid);
      if (!folderId) {
        return callback(fileInfo);
      }
      const { data: folder } = await addDocumentToFolder(modelType, modelId, folderId, newFile.id);
      return callback(newFile, folder);
    },
    {
      validators: [maxFileSize(maxUploadSize * 1024 * 1024)],
    }
  );
};
