<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import GuestListReportModal from '@/components/Models/guestLists/GuestListReportModal.vue';
import { getRoute } from '@/util/route';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import DisplayText from '@/components/Display/DisplayText.vue';
import GuestListTable from '@/components/Models/guestLists/GuestListTable.vue';
import { GuestListResource, GuestListSettingResource, GuestResource } from '@/types/festival';
import { AccreditationLevelResource } from '@/types/accreditation';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import CopyLink from '@/components/Helpers/CopyLink.vue';

type Props = {
  guestList: GuestListResource;
  guestListSettings: GuestListSettingResource;
  canEdit: boolean;
  accreditationLevels?: AccreditationLevelResource[] | null;
};

const props = withDefaults(defineProps<Props>(), {
  accreditationLevels: () => [],
});

const emits = defineEmits<{
  (event: 'update:guestList', arg: GuestListResource): void;
  (event: 'addOrUpdateGuest', arg: GuestListResource): void;
  (event: 'removeGuestId', arg: number): void;
  (event: 'fetch'): void;
  (event: 'edit'): void;
}>();

const allowedGuests = computed(() => {
  return props.guestList.allowed_guests ? props.guestList.allowed_guests : props.guestListSettings?.allowed_guests;
});

const showEditGuestListModal = ref(false);
const downloadingReport = ref(false);
const downloadModalOpen = ref(false);
const newlyCreatedGuestId = ref(null);

const addGuest = async () => {
  if (!props.guestList) return;
  if (!props.guestListSettings) return;
  if (props.guestList.guests.length >= allowedGuests.value) {
    useToast().warning('You have reached the maximum number of guests allowed.');
    return;
  }
  const { data } = await axios.post('/api/guests', {
    guest_list_id: props.guestList.id,
    approved: props.guestListSettings.auto_approve,
    name: `Guest ${props.guestList.guests.length + 1}`,
  });
  useToast().success('Guest added');
  const guestList = { ...props.guestList };
  guestList.guests.push(data);
  emits('update:guestList', guestList);
  emits('addOrUpdateGuest', data);
  newlyCreatedGuestId.value = data.id;
};

const options = computed(() => {
  if (!props.canEdit) return [];
  if (props.guestList === null) return [];
  return [
    {
      title: 'Add Guest',
      icon: 'fa-plus',
      onClick: () => {
        addGuest();
      },
    },
    {
      title: 'Edit Guest List',
      icon: 'fa-pencil',
      onClick: () => {
        emits('edit');
      },
    },
    {
      title: 'Report',
      icon: 'fa-download',
      loading: downloadingReport.value,
      onClick: () => {
        downloadModalOpen.value = false;
        nextTick(() => {
          downloadModalOpen.value = true;
        });
      },
    },
  ];
});

const addUuidToGuestList = async () => {
  const { data } = await axios.post('/api/guest-lists/' + props.guestList.id + '/uuid');
  const guestList = { ...props.guestList };
  guestList.uuid = data.uuid;
  emits('update:guestList', guestList);
  emits('fetch');
};

const updateGuest = (guest: GuestResource) => {
  const guestList = { ...props.guestList };
  guestList.guests = exchangeValuesOfObject(guest, guestList.guests);
  emits('update:guestList', guestList);
  emits('addOrUpdateGuest', guest);
};
const removeGuestById = (guestId: number) => {
  const guestList = { ...props.guestList };
  const index = getIndexFromArrayBasedOnId(guestId, guestList.guests);
  if (index > -1) {
    guestList.guests.splice(index, 1);
  }
  emits('update:guestList', guestList);
  emits('removeGuestId', guestId);
};

const open = ref(false);
</script>

<template>
  <ContentContainer
    :title="(guestList.title ? guestList.title + ' - ' : '') + 'Guest List'"
    :super-header="getKey(guestList.listable, 'name', null)"
    :actions="options"
    @open="open = $event">
    <template #underHeader>
      <div
        v-if="!open"
        class="grid grid-cols-3">
        <div class="flex">
          <DisplayBadge
            class="min-w-[100px]"
            :color="guestList.guests.length >= allowedGuests ? 'success' : 'pending'"
            :text="'Guests: ' + guestList.guests.length + '/' + allowedGuests"></DisplayBadge>
          <!--          <InputLabel-->
          <!--            super-text-->
          <!--            label="Guests Added"></InputLabel>-->
          <!--          {{ guestList.guests.length }} / {{ allowedGuests }}-->
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col overflow-hidden">
        <div
          v-if="guestList"
          class="grid grid-cols-[150px_200px_100px_auto] p-edge">
          <div>
            <InputLabel
              super-text
              label="Guests Added"></InputLabel>
            {{ guestList.guests.length }} / {{ allowedGuests }}
          </div>
          <div v-if="guestList && accreditationLevels && accreditationLevels.length > 0">
            <InputLabel
              super-text
              label="Accreditation Level"></InputLabel>
            <template v-if="guestList.accreditation_level_id">
              {{
                getItemFromArrayBasedOnId(guestList.accreditation_level_id, accreditationLevels, {
                  title: 'N/A',
                }).title
              }}
            </template>
            <template v-else-if="accreditationLevels.length > 0">
              {{
                getItemFromArrayBasedOnId(guestListSettings.accreditation_level_id, accreditationLevels, {
                  title: 'N/A',
                }).title
              }}
            </template>
          </div>
          <div v-if="guestList">
            <InputLabel
              super-text
              label="Public Link"></InputLabel>
            <template v-if="guestList.uuid">
              <CopyLink
                title="guest list"
                only-button
                open-button
                :link="getRoute('guestLists.show', props.guestList.uuid)" />
            </template>
            <template v-else>
              <div
                class="btn btn-outline-text btn-xs cursor-pointer w-[100px]"
                @click="addUuidToGuestList">
                Make Public
              </div>
            </template>
          </div>
          <div v-if="guestList.notes">
            <InputLabel
              super-text
              label="Notes"></InputLabel>
            <DisplayText
              class="!mt-0"
              :content="guestList.notes" />
          </div>
        </div>
        <div class="flex-1 overflow-auto">
          <GuestListTable
            v-if="guestList && guestList.guests.length"
            :can-edit="canEdit"
            :guest-list="guestList"
            :newly-created-guest-id="newlyCreatedGuestId"
            @add-or-update-guest="updateGuest($event)"
            @remove-guest-id="removeGuestById($event)" />
        </div>
        <EmptyStateFullPage
          v-if="guestList === null && guestListSettings?.id"
          icon="fa-list"
          :description="'No Guest List has been added.'"
          button-text="Create Guest List"
          :button-function="
            canEdit
              ? () => {
                  [(showEditGuestListModal = false), $nextTick(() => (showEditGuestListModal = { id: null }))];
                }
              : null
          " />
        <EmptyStateFullPage
          v-if="guestList !== null && guestList.guests.length === 0 && guestListSettings?.id"
          icon="fa-list"
          :description="'No Guests have been added.'"
          button-text="Add Guest"
          :button-function="
            canEdit
              ? () => {
                  addGuest();
                }
              : null
          " />
      </div>
    </template>
  </ContentContainer>
  <GuestListReportModal
    v-if="downloadModalOpen"
    v-model:downloading="downloadingReport"
    :guest-list="guestList"
    :guest-list-settings="guestListSettings" />
</template>
