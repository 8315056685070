<script lang="ts" setup>
import { computed, getCurrentInstance, inject, onMounted, ref } from 'vue';
import { createUuId } from '@/util/globals';

type Props = {
  componentType?: string;
  mainCell?: boolean;
  noBackground?: boolean;
  classes?: string;
  clickable?: boolean;
  sortUp?: boolean;
  sortDown?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  componentType: 'td',
  mainCell: false,
  noBackground: false,
  classes: '',
  clickable: false,
  sortUp: null,
  sortDown: null,
});

defineEmits<{
  (e: 'clicked', event: MouseEvent): void;
}>();

const currentHoverId = inject('currentHoverId', null);
const isMainRow = inject('mainRow', false);

const id = createUuId('cell_');
const instance = getCurrentInstance();
const cell = ref<HTMLElement | null>(null);
const isHeader = ref(false);

const getNumber = () => {
  setTimeout(() => {
    try {
      if (instance.parent.props.hasOwnProperty('head') && props.componentType !== 'th') {
        isHeader.value = instance.parent.props.head;
      } else if (props.componentType === 'th') {
        isHeader.value = true;
      }
    } catch (_) {}
  }, 50);
};

onMounted(() => {
  getNumber();
});

const mouseAction = (over = true) => {
  if (!currentHoverId) return;
  const hoverId = isHeader.value ? id : cell.value?.getAttribute('data-column-hover-id');
  if (hoverId) {
    currentHoverId?.update(hoverId);
  }
};

const currentlyHovered = computed(
  () => currentHoverId && currentHoverId.currentHoverId && id && currentHoverId.currentHoverId.value === id
);

const getClasses = () => {
  let classes = '';

  if (isHeader.value || props.componentType === 'th') {
    classes += 'text-sm ';
  } else {
    classes += 'text-base ';
  }

  if (props.clickable) {
    classes += ' cursor-pointer ';
  }
  if (isHeader.value) {
    classes += ' text-textColor relative';
  }

  if (!classes.includes('textColor')) {
    if (!props.mainCell && !isMainRow.value) {
      classes += '  text-textColor-soft ';
    }
  }

  if (props.noBackground) {
    classes += ' !bg-row-hover ';
  }

  if (props.classes) {
    classes += ' ' + props.classes + ' ';
  }

  return classes;
};

const filterCurrentlyActive = () => {
  return isHeader.value && (props.sortDown || props.sortUp);
};
</script>

<template>
  <component
    :is="isHeader ? 'th' : componentType"
    :id="isHeader ? id : null"
    ref="cell"
    class="text-left"
    :class="[getClasses(), { 'truncate': componentType === 'th' || isHeader }, { 'align-bottom': isHeader }]"
    @click="$emit('clicked', $event)"
    @mouseover="mouseAction(true)"
    @mouseleave="mouseAction(false)">
    <div class="left-side-border absolute top-0 left-0 hidden h-full !w-[1px] bg-borderColor !p-0" />
    <div :class="{ 'flex items-center': sortDown !== null && sortUp !== null }">
      <slot />
      <i
        v-if="sortDown !== null && sortUp !== null"
        class="fa fa-fw"
        :class="
          (sortDown ? 'fa-sort-down' : '') +
          (sortUp ? 'fa-sort-up' : '') +
          (sortDown === false && sortUp === false ? 'fa-sort' : '')
        " />
      <div
        v-if="(isHeader && currentlyHovered) || filterCurrentlyActive()"
        class="absolute bottom-0 left-0 h-[2px] w-full bg-textColor" />
    </div>
    <div class="right-side-border absolute top-0 right-0 hidden h-full !w-[1px] bg-borderColor !p-0" />
  </component>
</template>
