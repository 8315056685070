<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { getKey } from '@/util/globals';
import { highlightStringBySearch } from '@/util/text-replace-helper';
import { slugify } from '@/util/string-utils';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

type Options = string[] | object[] | number[] | ReadonlyArray<string | number | object>;

type OptionValueFunction = (option: any) => string;

type Props = {
  label?: string;
  labelTitle?: string;
  options: Options;
  canEdit?: boolean;
  optionKey?: string;
  optionValue?: string | OptionValueFunction;
  modelValue: string | number | object | null | boolean;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  nullable?: boolean;
  nullableDisplayText?: string;
  groups?: boolean;
  object?: boolean;
  isHidden?: boolean;
  labelClass?: string;
  title?: string | null;
  labelPlacement?: string;
  wrapperClass?: string;
  setFocus?: boolean;
  selectClass?: string;
  overrideWidth?: number | null;
  inputAreaClass?: string | null;
  arrowSide?: 'left' | 'right';
  hideArrow?: boolean;
  useNullable?: boolean;
  tabindex?: number;
  iconLeft?: string | null;
  canSearch?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: true,
  label: '',
  labelTitle: null,
  labelPlacement: 'top',
  optionKey: 'id',
  inputLabel: '',
  optionValue: 'name',
  placeholder: 'select',
  required: false,
  disabled: false,
  error: '',
  nullableDisplayText: 'None',
  groups: false,
  object: false,
  isHidden: false,
  setFocus: false,
  labelClass: '',
  wrapperClass: '',
  selectClass: '',
  overrideWidth: null,
  title: null,
  inputAreaClass: null,
  iconLeft: null,
  arrowSide: 'right',
  hideArrow: false,
  useNullable: true,
  tabindex: 0,
  canSearch: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void;
  (e: 'dropdownClosed'): void;
  (e: 'dropdownOpened'): void;
  (e: 'focus', arg: boolean): void;
}>();

const inFocus = ref(false);
const onSelect = (value: object | string | number | null) => {
  // If group label click just return
  if (value?.groupTitle) return;

  // emit null if nullable is true
  if (typeof value === 'object' && value[props.optionKey] === null) {
    emit('update:modelValue', null);
    return;
  }

  // if emit hole object
  if (props.object) {
    emit('update:modelValue', value);
    return;
  }

  if (typeof value === 'object') {
    const newValue = value[props.optionKey];
    if (newValue !== props.modelValue) {
      emit('update:modelValue', newValue);
    }
  } else {
    emit('update:modelValue', value);
  }
};

const optionType = computed(() => {
  if (props.groups) {
    return 'object';
  }
  if (props.options === null) {
    return 'string';
  }
  if (props.options.length === 0) {
    return 'undefined';
  }
  return typeof props.options[0];
});

const hiddenIds = ref<Set<string>>(new Set());

const allOptions = computed(() => {
  const array = [];

  if ((props.nullable && props.useNullable && optionType.value === 'object') || optionType.value === 'undefined') {
    array.push({
      [props.optionKey]: null,
      [props.optionValue]: props.nullableDisplayText ?? '',
    });
  }
  if (props.groups) {
    props.options.forEach((o) => {
      array.push({
        [props.optionValue]: o.label,
        [props.optionKey]: o.label,
        groupTitle: true,
      });
      o.options.forEach((oo: any) => {
        array.push(oo);
      });
    });
  } else {
    if (props.nullable && props.useNullable && optionType.value === 'string') {
      array.push(props.nullableDisplayText);
    }
    props.options?.forEach((o) => {
      array.push(o);
    });
  }

  return array;
});

const onClick = (item, close: (t) => void) => {
  if (item.hasOwnProperty('disabled') && item.disabled) return;
  if (item.groupTitle) return;
  close(item);
};

const isSelected = (option: any): boolean => {
  if (props.modelValue === null) {
    return option[props.optionKey] === null;
  }

  if (props.object) {
    return props.modelValue[props.optionKey] === option[props.optionKey];
  }

  if (typeof option === 'object') {
    return option[props.optionKey] === props.modelValue;
  } else {
    return option === props.modelValue;
  }
};

const selectedOption = computed(() => {
  if (!allOptions.value.length) return null;

  if (!props.nullable && props.modelValue === null) return null;

  if (props.object) {
    if (props.modelValue === null) {
      return allOptions.value.find((o) => o[props.optionKey] === null);
    }
    return props.modelValue;
  }

  if (props.groups) {
    return allOptions.value.find((o) => {
      return o[props.optionKey] === props.modelValue;
    });
  }

  if (allOptions.value.every((o) => typeof o === 'object')) {
    return allOptions.value.find((o: any) => o[props.optionKey] === props.modelValue);
  }

  if (props.nullable) {
    if (props.modelValue === null) {
      return allOptions.value.find((o) => o[props.optionKey] === null);
    }
  }

  return allOptions.value.find((o) => {
    return o === props.modelValue;
  });
});

const getOptionLabel = (option: any, highlight = false): string => {
  if (typeof props.optionValue === 'function') {
    return (props.optionValue as OptionValueFunction)(option);
  } else if (typeof option === 'object') {
    if (searchText.value.length && highlight) {
      return highlightStringBySearch(option[props.optionValue], searchText.value);
    }
    if (option) {
      return option[props.optionValue] ?? '';
    }
    return '';
  } else {
    if (searchText.value.length && highlight) {
      return highlightStringBySearch(option, searchText.value);
    }
    return option;
  }
};

const pos = computed(() => {
  switch (props.labelPlacement.toLowerCase()) {
    case 'left': {
      return '';
    }
    default: {
      return 'flex-col';
    }
  }
});

const element = ref<HTMLDivElement>();
const width = ref<string>('150px');

onMounted(async () => {
  await nextTick();
  if (!element.value) return;

  if (props.overrideWidth) {
    width.value = props.overrideWidth;
    return;
  }

  if (getComputedStyle(element?.value).width > '150px') {
    width.value = getComputedStyle(element?.value).width;
  }
});

const clickAreaClasses = (isOpen: boolean) => {
  const baseClasses = ['relative', 'h-full', 'cursor-pointer', 'rounded-md', 'data-[active=true]:ring-highlight'];

  if (isOpen) {
    baseClasses.push('ring-1', 'ring-highlight');
  }

  if (!props.canEdit) {
    baseClasses.push('!cursor-not-allowed');
  }

  if (props.isHidden) {
    baseClasses.push(...['pl-2']);
    if (props.canEdit) {
      baseClasses.push(...['hover:ring-1', 'hover:ring-borderColor']);
    }
  } else {
    baseClasses.push(
      ...[!props.canEdit ? ' bg-inputs-disabledBackground ' : 'bg-inputs-background', 'ring-1', 'ring-borderColor']
    );
    if (props.canEdit) {
      baseClasses.push(...['hover:ring-textColor-soft']);
    }
  }

  if (props.inputAreaClass) {
    baseClasses.push(props.inputAreaClass);
  }

  return baseClasses;
};

const searchText = ref('');

const focusItemId = ref<string | undefined | null>();

const isOpen = ref(false);

const searchInput = ref<HTMLInputElement | null>(null);

const scrollToItem = () => {
  window.requestAnimationFrame(() => {
    const element = document.querySelector(`[data-id="${focusItemId.value}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  });
};

const onKeyDown = (e: KeyboardEvent) => {
  const filteredOptions =
    optionType.value === 'object'
      ? allOptions.value.filter((o) => !hiddenIds.value.has(o[props.optionKey]))
      : allOptions.value.filter((o) => !hiddenIds.value.has(o));

  if (e.key === 'ArrowDown') {
    e.preventDefault();
    if (props.nullable && focusItemId.value === undefined) {
      if (optionType.value === 'object') {
        focusItemId.value = null;
      } else {
        focusItemId.value = filteredOptions[0];
      }
      return;
    } else if (focusItemId.value === null) {
      const index = props.nullable && filteredOptions.length > 1 ? 1 : 0;
      focusItemId.value = filteredOptions[index][props.optionKey];
      return;
    }

    const index =
      optionType.value === 'object'
        ? filteredOptions.findIndex((o) => o[props.optionKey] === focusItemId.value)
        : filteredOptions.findIndex((o) => o === focusItemId.value);

    if (index === filteredOptions.length - 1) {
      focusItemId.value = optionType.value === 'object' ? filteredOptions[0][props.optionKey] : filteredOptions[0];
      scrollToItem();
      return;
    }
    focusItemId.value =
      optionType.value === 'object' ? filteredOptions[index + 1][props.optionKey] : filteredOptions[index + 1];
    scrollToItem();
    return;
  }
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    if (focusItemId.value === null) {
      focusItemId.value =
        optionType.value === 'object'
          ? filteredOptions[filteredOptions.length - 1][props.optionKey]
          : filteredOptions[filteredOptions.length - 1];
      scrollToItem();
      return;
    }

    const index =
      optionType.value === 'object'
        ? filteredOptions.findIndex((o) => o[props.optionKey] === focusItemId.value)
        : filteredOptions.findIndex((o) => o === focusItemId.value);

    if (index <= 0) {
      focusItemId.value =
        optionType.value === 'object'
          ? filteredOptions[filteredOptions.length - 1][props.optionKey]
          : filteredOptions[filteredOptions.length - 1];
      scrollToItem();
      return;
    }

    focusItemId.value =
      optionType.value === 'object' ? filteredOptions[index - 1][props.optionKey] : filteredOptions[index - 1];
    scrollToItem();
    return;
  }
  if (e.key === 'Enter') {
    e.preventDefault();
    const option =
      optionType.value === 'object'
        ? filteredOptions.find((o) => o[props.optionKey] === focusItemId.value)
        : filteredOptions.find((o) => o === focusItemId.value);
    if (option) {
      searchText.value = optionType.value === 'object' ? option[props.optionValue] : option;
      if (searchInput.value) {
        searchInput.value.blur();
      }
      onSelect(option);
      isOpen.value = false;
    }
    return;
  }
};

watch(inFocus, (open) => {
  if (open) {
    hiddenIds.value = new Set();
    window.addEventListener('keydown', onKeyDown);
    if (searchInput.value) {
      searchInput.value.focus();
    }
  } else {
    window.removeEventListener('keydown', onKeyDown);
    hiddenIds.value = new Set();
    searchText.value = '';
    focusItemId.value = undefined;
  }
});

const searchIsFocused = ref(false);

if (props.canSearch && props.canEdit) {
  watch(searchText, (value) => {
    if (value.length) {
      const filteredOptions = allOptions.value.filter((o) => {
        return !o[props.optionValue].toLowerCase().includes(searchText.value.toLowerCase());
      });
      let newHiddenIds = new Set([]);
      filteredOptions.forEach((o) => {
        newHiddenIds.add(o[props.optionKey]);
      });
      hiddenIds.value = newHiddenIds;
    } else {
      hiddenIds.value = new Set();
    }
  });
}

const onBlur = () => {
  searchIsFocused.value = false;
};
</script>

<template>
  <div
    class="flex h-full w-full"
    :class="[pos, wrapperClass]">
    <InputLabel
      v-if="label"
      :mandatory-text="required ? 'Required' : ''"
      :is-hidden="isHidden"
      :class="labelClass"
      :title="labelTitle ? labelTitle : title"
      :label="label" />
    <VDropdown
      v-model="isOpen"
      :items="allOptions"
      :item-key="optionKey"
      :item-label="optionValue"
      :close-on-click="true"
      :can-open-dropdown="canEdit"
      :set-focus="setFocus"
      @dropdown-closed="[$emit('dropdownClosed'), $emit('focus', false), (inFocus = false)]"
      @dropdown-opened="[$emit('dropdownOpened'), $emit('focus', true), (inFocus = true)]"
      @item-clicked="onSelect">
      <template #click-area="{ isOpen }">
        <div
          :data-active="isOpen"
          :tabindex="tabindex"
          :title="selectedOption ? getOptionLabel(selectedOption) : ''"
          class="focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight"
          :class="clickAreaClasses(isOpen)">
          <select class="invisible absolute h-0 w-full">
            <option
              v-for="option in allOptions"
              :key="optionType === 'object' ? option[optionKey] : option"
              class="truncate"
              :value="optionType === 'object' ? option[optionKey] : option"
              :selected="isSelected(option)">
              {{ getOptionLabel(option) }}
            </option>
          </select>
          <div
            ref="element"
            class="dropdown-element flex h-full w-full items-center justify-between gap-2 overflow-hidden rounded-md p-3">
            <div
              v-if="iconLeft"
              :class="inFocus ? ' text-textColor ' : ' text-textColor-soft '"
              class="absolute inline-flex h-full w-5 items-center justify-center">
              <i
                class="fa fa-fw mt-2"
                :class="iconLeft" />
            </div>

            <div v-if="arrowSide === 'left' && hideArrow === false">
              <i
                v-if="canEdit"
                class="fa fa-fw fa-chevron-down text-textColor-soft transition-transform duration-300 text-sm"
                :class="{ 'rotate-180': isOpen }" />
            </div>
            <div
              class="flex min-h-[24px] items-center truncate text-left"
              :class="[
                { 'cursor-not-allowed text-textColor-soft': !canEdit, 'pl-6': iconLeft },
                { 'flex-1': canSearch },
              ]">
              <div
                v-if="selectedOption || nullable"
                :class="{ 'w-full': canSearch }"
                class="truncate">
                <slot
                  name="single-label"
                  :value="selectedOption">
                  <input
                    v-if="canSearch && canEdit"
                    ref="searchInput"
                    :value="searchIsFocused ? searchText : getOptionLabel(selectedOption)"
                    :placeholder="getOptionLabel(selectedOption)"
                    type="text"
                    class="appearance-none h-[24px] border-none p-0 text-base placeholder:text-base bg-transparent w-full"
                    @input="searchText = $event.target.value"
                    @focus="searchIsFocused = true"
                    @blur="onBlur" />
                  <template v-else>
                    {{ getOptionLabel(selectedOption) }}
                  </template>
                </slot>
              </div>
              <span
                v-else
                class="pl-1 pr-2 text-placeholder italic text-textColor-soft">
                {{ placeholder }}
              </span>
            </div>
            <i
              v-if="arrowSide === 'right' && hideArrow === false && canEdit"
              class="fa fa-fw fa-chevron-down text-textColor-soft transition-transform duration-300 text-sm"
              :class="{ 'rotate-180': isOpen }" />
          </div>
        </div>
      </template>
      <template #dropdown="{ close }">
        <div
          class="min-w-full overflow-auto rounded-md ring"
          :style="`min-width: ${width};`">
          <ul class="bg-backgroundColor py-3">
            <li
              v-for="(option, index) in allOptions"
              :key="optionType === 'object' ? option[optionKey] : option"
              :title="getKey(option, 'hoverTitle')"
              :dusk="`dropdown-option-item-${slugify(getOptionLabel(option))}`"
              :class="[
                { 'hidden': hiddenIds.has(option[optionKey]) },
                { 'bg-row-hover': focusItemId === (optionType === 'object' ? option[optionKey] : option) },
                getKey(option, 'class'),
                { 'cursor-default': option.groupTitle },
                { ' hover:bg-row-hover ': !isSelected(option) && !option.groupTitle },
                { 'cursor-disabled text-textColor-soft': option.disabled },
                { ' ': !option.groupTitle && props.groups },
                { ' text-center text-highlight [&>*.flex]:block ': option.groupTitle && props.groups },
                { ' mt-5 ': option.groupTitle && props.groups && index !== 0 },
                { 'min-h-[25px]': getOptionLabel(option)?.length < 2 },
                option.disabled || option.groupTitle || isSelected(option) ? '' : ' cursor-pointer',
              ]"
              class="px-4 py-2 max-w-[485px]"
              :data-id="optionType === 'object' ? option[optionKey] : option"
              @click="onClick(option, close)">
              <slot :option="option">
                <div class="flex items-center justify-between gap-2">
                  <slot
                    name="option-label"
                    :option="option">
                    <span :class="{ 'text-textColor-soft': isSelected(option) }">
                      <i
                        v-if="getKey(option, 'icon')"
                        class="fa fa-fw"
                        :class="getKey(option, 'icon')"></i>
                      <span
                        v-if="searchText.length"
                        v-html="safeHtmlStringify(getOptionLabel(option, true))" />
                      <span v-else> {{ getOptionLabel(option) }} </span>
                    </span>
                  </slot>
                  <div
                    v-if="!option.groupTitle"
                    style="width: 15px">
                    <div v-if="focusItemId === (optionType === 'object' ? option[optionKey] : option)">
                      <i class="fa fa-fw fa-arrow-left" />
                    </div>
                    <i
                      v-else-if="isSelected(option)"
                      class="fa fa-fw fa-check text-highlight" />
                  </div>
                </div>
              </slot>
            </li>
            <li v-if="searchText.length && hiddenIds.size === allOptions.length">
              <div class="px-4 py-2">
                <span class="text-textColor-soft">No results found</span>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
