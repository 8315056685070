import { FormModelType, FormResource } from '@/types/form';

export const getForms = async (model: FormModelType, modelId: number) => {
  return axios.get<FormResource[]>('/api/forms', {
    params: {
      model_type: `App\\${model}`,
      model_id: modelId,
    },
  });
};

export const getForm = async (formId: number) => {
  return axios.get<FormResource>(`/api/forms/${formId}`);
};

type FormData = {
  title: string;
  parent_id: number | null;
  is_global: boolean;
  start: string | null;
  end: string | null;
  connect_to_document_folder: boolean;
  restrict_edit: boolean;
  redirect_url: string | null;
  is_locked: boolean;
  text: string | null;
};
export const postForm = async (model: FormModelType, modelId: number, data: FormData) => {
  return axios.post<FormResource>('/api/forms', {
    model_type: `App\\${model}`,
    model_id: modelId,
    ...data,
  });
};

export const patchForm = async (formId: number, data: Omit<FormData, 'is_global'>) => {
  return axios.patch<string>('/api/forms/' + formId, {
    title: data.title,
    start: data.start,
    end: data.end,
    connect_to_document_folder: data.connect_to_document_folder,
    restrict_edit: data.restrict_edit,
    redirect_url: data.redirect_url,
    is_locked: data.is_locked,
    text: data.text,
  });
};

export const removeForm = async (formId: number) => {
  return axios.delete<string>('/api/forms/' + formId);
};

export const getFormTemplates = async () => {
  const url = '/api/form-templates';
  return axios.get<FormResource[]>(url);
};

type SectionData = {
  title: string;
  text: string;
  id: number;
  fields: {
    id: number;
    title: string;
    value: string;
    component: string;
    options: string;
    class: string;
    linebreak_after: boolean;
  }[];
};
export const patchFormSection = async (formId: number, sections: SectionData[]) => {
  const url = `/api/forms/${formId}/sections`;
  return axios.patch<string>(url, { sections });
};
