<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { ref } from 'vue';
import { getKey } from '@/util/globals';
import ActionButton from '@/components/Inputs/Components/ActionButton.vue';

export type Action = {
  icon: string;
  title: string;
  imgURL?: string;
  action: () => void;
  hoverText?: string;
  class?: string;
  disabled?: boolean;
  loading?: boolean;
  dropdown?: Action[];
  isDanger?: boolean;
  primary?: boolean;
};

type Props = {
  actions?: Action[];
  size?: string;
  inline?: boolean;
  rounded?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  size: 'regular',
  inline: false,
  rounded: true,
  actions: () => [],
});

const getSize = () => {
  let string = '';
  switch (props.size) {
    case 'small':
    case 'medium': {
      string += ' [&>*]:text-sm ';

      if (!props.inline) {
        string += ' [&>*]:h-7 ';
      } else {
        string += ' !h-7 ';
      }
      break;
    }
    case 'regular':
    default: {
      return '[&>*>i]:mr-1  [&>*]:h-full';
    }
  }
  return string;
};

const getInline = () => {
  let string = ' [&>*:disabled]:bg-disabled [&_button:hover]:text-black border-b ';

  if (props.inline) {
    string += ' flex h-full [&_button]:border-l [&>*]:h-full';
    if (props.rounded) {
      string += '  rounded-bl-md [&_button]:rounded-bl-md [&_button]:border-l ';
    }
  } else {
    string += ' absolute flex border-b top-0 right-0 rounded-bl-md [&>*]:rounded-bl-md [&_button]:border-l ';
  }
  return string;
};

const dropdown = ref(null);
const componentKey = ref(0);
[50, 100, 500, 1000].forEach((time) => {
  setTimeout(() => {
    componentKey.value++;
  }, time);
});

const buttonShouldBePrimary = (button) => {
  return false;
  const primaryButtonExists = props.actions.filter((a) => a !== null).find((a) => a.hasOwnProperty('primary'));
  if (primaryButtonExists) {
    return getKey(button, 'primary', false);
  }

  if (props.actions.filter((a) => a !== null && a.hasOwnProperty('icon') && a.icon === 'fa-plus').length >= 2) {
    return false;
  }

  return button.icon === 'fa-plus';
};
</script>

<template>
  <div
    :key="componentKey"
    :class="getSize() + getInline()"
    class="action-buttons flex [&_a]:text-textColor [&_.fa-fw]:w-[1.65em]">
    <slot>
      <template
        v-for="action in actions.filter((a) => a !== null)"
        :key="action.title">
        <template v-if="action.hasOwnProperty('dropdown')">
          <button
            v-if="action.dropdown.length === 1 && getKey(action.dropdown[0], 'type') !== 'header'"
            class="btn-sm group truncate bg-inputs-background [&:hover>i]:text-[unset]"
            :title="action.dropdown[0].title"
            :class="[
              action.class ? action.class : '',
              action.icon === 'fa-trash' || getKey(action, 'isDanger', false)
                ? ' hover:bg-warning [&>i]:text-warning '
                : ' hover:bg-highlight  [&>i]:text-highlight ',
            ]"
            @click="action.dropdown[0].action($event)">
            <i
              class="fa fa-fw"
              :class="
                action.hasOwnProperty('loading') && action.loading
                  ? 'fa-circle-o-notch fa-spin'
                  : getKey(action.dropdown[0], 'icon', 'fa-chevron-down')
              " />
            {{ getKey(action.dropdown[0], 'title', '') }}
          </button>
          <template v-else>
            <ActionButton
              v-if="getKey(action, 'dropdownAsButton', false)"
              :primary-action="buttonShouldBePrimary(action)"
              :action="action" />
            <VDropdown
              :close-on-click="action.hasOwnProperty('closeOnClick') ? action.closeOnClick : true"
              :max-height-dropdown="getKey(action, 'maxHeightDropdown', '300px')"
              class="action-button-dropdown flex-[unset] overflow-hidden !p-0"
              :items="action.dropdown">
              <template #click-area>
                <div
                  ref="dropdown"
                  :class="getSize()"
                  @click="[action.hasOwnProperty('loadData') ? action.loadData() : null]">
                  <button
                    class="btn-sm group font-headers truncate focus:outline-none [&:hover>i]:text-[unset]"
                    :title="
                      getKey(action, 'disabled', false) && getKey(action, 'disabledTitle') !== null
                        ? action.disabledTitle
                        : action.hoverText
                    "
                    :disabled="getKey(action, 'disabled', false)"
                    :class="[
                      getKey(action, 'dropdownAsButton', false)
                        ? ' !w-[25px] !min-w-[25px] !rounded-[0px] !border-l-[0px] !px-[2px]'
                        : '',
                      action.class ? action.class : '',
                      getKey(action, 'disabled', false)
                        ? ' !bg-disabled !text-textColor-disabled [&>i]:!text-textColor-disabled '
                        : '',
                      // getKey(action, 'primary', false)
                      //   ? ' !bg-success text-[#031d32] [&_i]:text-[#031d32] hover:!bg-success/90 '
                      //   : '',
                      action.icon === 'fa-trash' || getKey(action, 'isDanger', false)
                        ? ' hover:bg-warning [&>i]:text-warning '
                        : '  [&>i]:text-actionButton-icon   [&>i:hover]:text-actionButton-hoverIcon bg-actionButton-background  [&_i]:group-hover:!text-actionButton-hoverIcon hover:bg-actionButton-hoverBackground hover:!text-actionButton-hoverText text-actionButton-text ',
                    ]">
                    <template v-if="getKey(action, 'dropdownAsButton', false)">
                      <i class="fa fa-fw fa-chevron-down" />
                    </template>
                    <template v-else>
                      <i
                        class="fa fa-fw"
                        :class="
                          action.hasOwnProperty('loading') && action.loading
                            ? 'fa-circle-o-notch fa-spin'
                            : getKey(action, 'icon', 'fa-chevron-down')
                        " />
                      {{ getKey(action, 'title', '') }}
                    </template>
                  </button>
                </div>
              </template>
            </VDropdown>
          </template>
        </template>

        <ActionButton
          v-else
          :primary-action="buttonShouldBePrimary(action)"
          :action="action" />
      </template>
    </slot>
  </div>
</template>
