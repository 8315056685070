<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import { validEmail } from '@/util/validators';
import BaseInput from '@/components/Base/BaseInput.vue';
import { useLocalStoreDataListOptions } from '@/composables/use-local-store-data-list-options';

type Props = {
  email?: string | null;
  loading?: boolean;
  canEdit?: boolean;
  required?: boolean;
  isHidden?: boolean;
  setFocus?: boolean;
  label?: string;
  placeholder?: string;
  errorMessage?: string | null;
  wrapperClass?: string;
  classes?: string;
  leftIcon?: string | null;
  dataListOptionsLocalStorageString?: string | null;
  withClear?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  email: null,
  loading: false,
  canEdit: true,
  required: false,
  setFocus: false,
  isHidden: false,
  label: 'Email',
  placeholder: '',
  errorMessage: null,
  dataListOptionsLocalStorageString: null,
  wrapperClass: '',
  classes: '',
  withClear: false,
  leftIcon: 'fa-envelope',
});

const emit = defineEmits<{
  (event: 'update:email', arg: string): void;
  (event: 'blur', arg: string | null): void;
  (event: 'keyup', arg: string | null): void;
}>();

let allOptions: string[] = [];
let saveActions: ((event: string) => void) | null = null;

if (props.dataListOptionsLocalStorageString) {
  const { options, saveToLocalStorage } = useLocalStoreDataListOptions(
    props.dataListOptionsLocalStorageString,
    'last_use'
  );
  allOptions = options.value;
  saveActions = saveToLocalStorage;
}

const newEmail = ref(props.email);
const firstOutOfFocus = ref(false);
const inFocus = ref(false);

const isValidEmail = computed(() => {
  if (newEmail.value === null || newEmail.value === '') return true;
  return validEmail(newEmail.value);
});

watch(newEmail, (newValue) => {
  if (isValidEmail.value && newValue?.length) {
    emit('update:email', newValue);
  } else {
    emit('update:email', '');
  }
});

watch(
  () => props.email,
  (newValue) => {
    if (!inFocus.value) {
      newEmail.value = newValue;
    }
  }
);

const onBlur = () => {
  if (props.dataListOptionsLocalStorageString && saveActions && isValidEmail && newEmail.value) {
    saveActions(newEmail.value);
  }
};
</script>

<template>
  <BaseInput
    v-model="newEmail"
    type="email"
    :label="label"
    :force-lower-case="true"
    autocapitalize="none"
    :placeholder="placeholder"
    :is-hidden="isHidden"
    :required="required"
    :loading="loading"
    :set-focus="setFocus"
    :wrapper-class="wrapperClass"
    :input-class-name="classes"
    :error-message="firstOutOfFocus && !isValidEmail ? 'Invalid email' : errorMessage"
    :can-edit="!loading && canEdit"
    :left-icon="leftIcon"
    :data-list-options="allOptions.filter((option) => validEmail(option))"
    :with-clear="withClear"
    @keyup="$emit('keyup', $event)"
    @focus="inFocus = true"
    @blur="[(inFocus = false), (firstOutOfFocus = true), $emit('blur', isValidEmail ? $event : null), onBlur($event)]">
    <template
      v-if="$slots.end"
      #end>
      <slot name="end" />
    </template>
  </BaseInput>
</template>
