<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import TextInput from '@/components/Inputs/TextInput.vue';
import HoverBox from '@/components/HoverBox.vue';

type Props = {
  modelValue?: string | null;
  url: string;
  searchParam?: string;
  params?: object | null;
  canEdit?: boolean;
  canCreate?: boolean | (() => void);
  minSearchLength?: number;
  label?: string | null;
  optionKey?: string;
  optionLabel?: string;
  debounceTime?: number;
  placeholder?: string | null;
  additionalSearchString?: string;
  leftIcon?: string;
  withClear?: boolean;
  autofocus?: boolean;
  canSearch?: boolean;
  alreadySelectedItemIds?: string[] | number[];
  createButtonText?: string | number;
  createButtonAfterText?: string;
  maxDropdownHeight?: number | null;
  noResultsText?: string | null;
  hideNoResultsWhenCreateButton?: boolean;
  initSearchString?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  searchParam: 'q',
  params: null,
  canEdit: true,
  canCreate: false,
  canSearch: true,
  minSearchLength: 1,
  label: null,
  optionKey: 'id',
  optionLabel: 'name',
  debounceTime: 0,
  maxDropdownHeight: 200,
  placeholder: 'search',
  additionalSearchString: '',
  leftIcon: 'fa-search',
  createButtonText: 'Create',
  createButtonAfterText: '',
  initSearchString: '',
  withClear: true,
  autofocus: false,
  alreadySelectedItemIds: () => [],
  noResultsText: 'No Options found',
  hideNoResultsWhenCreateButton: false,
});

const emit = defineEmits<{
  (e: 'selected', option: unknown): void;
  (e: 'create', option: string): void;
  (e: 'searchString', option: string): void;
}>();

const searchElement = ref<HTMLElement | null>(null);
const searchString = ref(
  props.isObject && props.modelValue
    ? props.modelValue[props.optionLabel]
    : props.initSearchString
      ? props.initSearchString
      : ''
);
const xPos = ref(0);
const yPos = ref(0);
const width = ref(100);
const dropDownOpen = ref(false);
const options = ref([]);
const loading = ref(false);
const hasSearched = ref(false);

const openDropDrown = async () => {
  if (dropDownOpen.value || !searchElement.value) return;

  width.value = searchElement.value.getBoundingClientRect().width;
  xPos.value = searchElement.value.getBoundingClientRect().x;
  yPos.value = searchElement.value.getBoundingClientRect().bottom + 3;
  await nextTick();
  dropDownOpen.value = true;
};

const debouncedFn = useDebounceFn(async () => {
  loading.value = true;
  const params = { ...props.params };
  params[props.searchParam] = `${searchString.value} ${props.additionalSearchString}`;
  const { data, ...rest } = await axios.get(props.url, { params });
  if (rest.status === 204) {
    return;
  }
  if (data?.data) {
    options.value = data.data;
  }
  hasSearched.value = true;
  loading.value = false;
}, props.debounceTime);

const searchForOptions = async () => {
  if (!props.canSearch) return;
  if (props.minSearchLength > searchString.value.length) {
    options.value = [];
    loading.value = false;
    return;
  }
  await debouncedFn();
};

const createElement = () => {
  dropDownOpen.value = false;
  emit('create', searchString.value);
  searchString.value = '';
};

const onOptionClick = (option) => {
  if (props.alreadySelectedItemIds && props.alreadySelectedItemIds.includes(option.id)) return;

  searchString.value = '';
  dropDownOpen.value = false;
  emit('selected', option);
};

let ticking = false;

const update = () => {
  if (!searchElement.value) return;

  ticking = false;

  width.value = searchElement.value.getBoundingClientRect().width;
  xPos.value = searchElement.value.getBoundingClientRect().x;
  yPos.value = searchElement.value.getBoundingClientRect().bottom;
};

const requestTick = () => {
  if (!ticking) {
    requestAnimationFrame(update);
  }
  ticking = true;
};

const onScroll = () => {
  requestTick();
};

window.addEventListener('scroll', onScroll, true);

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

watch(searchString, () => {
  emit('searchString', searchString.value);
  // searchForOptions();
});

const onKeyUp = () => {
  searchForOptions();
  if (searchString.value.length >= props.minSearchLength) {
    openDropDrown();
  } else {
    dropDownOpen.value = false;
  }
};

const allowEdit = computed(() => {
  if (props.isObject && props.modelValue) {
    return !props.modelValue[props.optionKey] && props.canEdit;
  }
  return props.canEdit;
});

const allowCreate = computed(() => {
  if (typeof props.canCreate === 'function') {
    return props.canCreate(searchString.value);
  }
  return props.canCreate;
});

const forceThatOpen = () => {
  [0, 10, 50, 100, 150, 300, 500].forEach((time) => {
    setTimeout(() => {
      if (dropDownOpen.value) return;
      if (searchString.value?.length === 0) return;
      openDropDrown();
    }, time);
  });
};
if (props.initSearchString) {
  searchForOptions();
}

defineSlots<{
  default?: (props: { tab: string }) => any;
}>();
</script>

<template>
  <div ref="searchElement">
    <TextInput
      v-model="searchString"
      :with-clear="!!searchString?.length"
      :placeholder="placeholder"
      :loading="loading"
      :can-edit="allowEdit"
      :left-icon="leftIcon"
      :autofocus="autofocus"
      :label="label"
      :force-lower-case="true"
      @keydown="hasSearched = false"
      @clear="searchString = ''"
      @focus="forceThatOpen"
      @keyup="onKeyUp" />
    <HoverBox
      v-if="dropDownOpen"
      :x-pos="xPos"
      :y-pos="yPos"
      @closed="dropDownOpen = false">
      <ul
        class="min-h-[40px] divide-y rounded border-b border-l border-r bg-backgroundColor-content"
        :style="`width:${width}px;`">
        <li>
          <div
            :style="`max-height: ${maxDropdownHeight}px`"
            class="overflow-auto">
            <ul class="divide-y">
              <li
                v-for="option in options"
                :key="option[optionKey]"
                class="h-[40px] cursor-pointer p-2 hover:bg-backgroundColor"
                :disabled="alreadySelectedItemIds && alreadySelectedItemIds.includes(option.id)"
                @click="onOptionClick(option)">
                <slot :option="option">
                  {{ option[optionLabel] }}
                </slot>
              </li>
            </ul>
          </div>
        </li>

        <li
          v-if="!options.length && searchString?.length < minSearchLength"
          class="h-[40px] p-2">
          Type at least {{ minSearchLength }} to search
        </li>
        <li
          v-if="
            !options.length &&
            searchString?.length >= minSearchLength &&
            !loading &&
            hasSearched &&
            noResultsText &&
            (!hideNoResultsWhenCreateButton || !allowCreate)
          "
          class="h-[40px] p-2 flex items-center pl-[35px] text-textColor-soft">
          {{ noResultsText }}
        </li>
        <li
          v-if="allowCreate"
          class="h-[40px] cursor-pointer grid grid-cols-[35px_auto] items-center p-2 hover:bg-backgroundColor pt-3"
          @click="createElement">
          <i class="fa fa-fw fa-plus" />
          <div>
            {{ createButtonText }}
            <span class="text-highlight">
              {{ searchString }}
            </span>
            {{ createButtonAfterText }}
          </div>
        </li>
      </ul>
    </HoverBox>
  </div>
</template>
