<script lang="ts" setup>
import ActiveUsers from '@/components/Navbar/ActiveUsers.vue';
import NotificationListModal from '@/components/Navbar/NotificationListModal.vue';
import { usePageTabsStore } from '@/store/PageTabsStore';
import { goBack, openExternalPage } from '@/util/route';
import { usePage } from '@inertiajs/vue3';
import { ref } from 'vue';
import TheSubNav from '@/components/Navbar/TheSubNav.vue';
import { useNavBarTitleStore } from '@/composables/use-nav-bar-title';
import UserMenu from '@/components/Navbar/UserMenu.vue';
import TableButtonSelector from '@/components/Tables/Headers/TableButtonSelector.vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

const pageTabsStore = usePageTabsStore();

const useNavBarTitleStore1 = useNavBarTitleStore();

const notificationSlideOutOpen = ref(false);
const items = () => {
  return [
    {
      title: 'Knowledge Base ',
      preIcon: 'fa-book fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://help.crescat.io', null, true);
        close();
      },
    },
    {
      type: 'divider',
    },
    {
      title: 'Feedback & Suggestions',
      preIcon: 'fa-envelope-open-text fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_suggestion', null, true);
        close();
      },
    },
    {
      title: 'Ask a question',
      preIcon: 'fa-message-question fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_question', null, true);
        close();
      },
    },
    {
      title: 'Report an Issue',
      preIcon: 'fa-flag fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_issue', null, true);
        close();
      },
    },
    {
      type: 'divider',
    },
    {
      title: "What's new in Crescat?",
      preIcon: 'fa-bullhorn fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://crescat.io/release-notes/', null, true);
        close();
      },
    },
  ];
};
</script>

<template>
  <nav
    :class="[{ 'border-b': !useNavBarTitleStore1.navBarMinimum }]"
    class="h-navCurrentHeight main-nav flex flex-col justify-between overflow-hidden bg-navbar pt-3">
    <div class="grid grid-cols-[3fr_1fr] gap-5 pr-edge items-center">
      <div class="flex flex-col justify-between overflow-hidden">
        <div class="flex h-6 items-center gap-5">
          <div
            id="breadcrumb"
            class="h-full flex-1" />
          <div id="after-breadcrumb" />
        </div>
      </div>

      <div class="flex items-center gap-3 justify-end">
        <div id="before-navbar" />

        <div
          v-if="usePage().props.authenticated"
          id="custom_notification_area" />
        <div
          v-if="usePage().props.authenticated"
          id="share_or_invite_button_area" />

        <ActiveUsers v-if="$page.props.authenticated" />

        <NotificationListModal
          v-if="usePage().props.authenticated"
          v-model:is-open="notificationSlideOutOpen" />

        <TableButtonSelector
          v-if="usePage().props.authenticated"
          dusk="navbar-help-button"
          icon="fa-question"
          tool-tip-text="Need Help?"
          :selected-text="''"
          button-text="Help"
          :options="items()" />

        <UserMenu
          v-if="usePage().props.authenticated"
          dusk="navbar-user-menu" />
      </div>
    </div>
    <div
      :class="{ 'pb-[20px]': pageTabsStore.tabs.length === 0 || !pageTabsStore.inNavbar }"
      class="px-edge flex items-center gap-3">
      <div
        v-if="useNavBarTitleStore1.navBarBackButton"
        class="w-[35px] h-full hover:bg-backgroundColor-primaryElement ml-[-8px] cursor-pointer flex items-center rounded"
        @click="goBack()">
        <i class="fa fa-fw fa-arrow-left text-2xl mx-auto"></i>
      </div>
      <h1
        class="font-headers truncate"
        style="font-size: 1.5rem"
        v-html="safeHtmlStringify(useNavBarTitleStore1.navBarTitle)" />
    </div>

    <TheSubNav />
  </nav>
</template>
