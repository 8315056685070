<script setup lang="ts">
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { createUuId } from '@/util/globals';
import DisplayText from '@/components/Display/DisplayText.vue';
import { nextTick, ref } from 'vue';
import { WhisperObject } from '@/util/whisper-functions';

type Props = {
  modelValue?: string | false | null;
  canEdit?: boolean;
  autoFocus?: boolean;
  whisper?: WhisperObject | null;
  tabindex?: number | null;
  dataListOptions?: [] | null;
  placeholder?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  canEdit: false,
  autoFocus: true,
  whisper: null,
  placeholder: null,
  tabindex: null,
  dataListOptions: () => [],
});

const emit = defineEmits<{
  (e: 'update:modelValue', arg: string | null): void;
  (e: 'keydown-enter', arg: KeyboardEvent): void;
  (event: 'keydown-esc', value: KeyboardEvent): void;
}>();

const edit = ref(false);
const cellId = createUuId('text_cell_');

const emitValue = (v) => {
  if (props.modelValue === null && v.trim().length === 0) return;
  if (v !== props.modelValue) {
    if (v.length > 0) {
      emit('update:modelValue', v.trim());
    } else {
      emit('update:modelValue', null);
    }
  }
};

const onChange = (e) => {
  emitValue(e.target.modelValue);
};

const onChangeEnter = (e) => {
  emitValue(e.target.modelValue);
  edit.value = false;
};

const keydownEnter = (e) => {
  if (e.shiftKey) return;
  emit('keydown-enter', e);
};

const onChangeEsc = () => {
  edit.value = false;
};

const onBlur = (e) => {
  nextTick(() => {
    if (edit.value) {
      emitValue(e.target.modelValue);
      edit.value = false;
    }
  });
};
const posX = ref(null);
const posY = ref(null);

// edit() {
//   this.$parent.$parent.isEditing = this.edit;
//   if (!this.autoFocus) return;
//   setTimeout(() => {
//     const element = document.getElementById(this.cellId);
//     if (element) {
//       element.focus();
//     }
//   }, 100);
// },
</script>

<template>
  <div
    v-if="canEdit"
    class="flex w-full cursor-pointer items-center justify-center h-full"
    @click="edit = true">
    <TextareaInput
      :id="cellId"
      :data-list-options="dataListOptions"
      wrapper-class="w-full hover:ring-1 [&_textarea]:!rounded-none"
      autofocus
      :model-value="modelValue"
      :placeholder="placeholder"
      :new-line-on-enter="false"
      :can-edit="canEdit"
      is-hidden
      :tabindex="tabindex"
      :min-rows="1"
      :min-height="40"
      :whisper="whisper"
      @keydown-esc="emit('keydown-esc', $event)"
      @data-list-selected="emitValue($event)"
      @blur="(e) => [emitValue(e), (edit = false)]" />
  </div>
  <div
    v-else
    class="flex items-center justify-center py-[8px] px-[13px]">
    <DisplayText
      v-if="modelValue"
      :content="modelValue" />
  </div>
</template>
