<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import { useInfiniteScroll } from '@vueuse/core';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import {
  RunningOrderAuditResource,
  RunningOrderCellResource,
  RunningOrderColumnResource,
  RunningorderResource,
  RunningOrderRowResource,
} from '@/types/runningorder';
import { usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';

type Props = {
  runningOrder: RunningorderResource;
  runningOrderRow?: RunningOrderRowResource | null;
  runningOrderColumn?: RunningOrderColumnResource | null;
  runningOrderCell?: RunningOrderCellResource | null;
};

const props = withDefaults(defineProps<Props>(), {
  runningOrderRow: null,
  runningOrderColumn: null,
  runningOrderCell: null,
});

const target = ref(null);

const loading = ref(false);
const open = ref(false);

const loadingSelectedAudit = ref(false);

const audits = ref<RunningOrderAuditResource[]>([]);
const activeFields = ref([]);
const allowedFields = ref(['order', 'title', 'component', 'options', 'width', 'value']);
const page = ref(1);
const totalPages = ref(1);
const totalAudits = ref(null);
const selectedAudit = ref(null);

const auditEventTypes = ref([
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
]);

const allAuditEventTypes = [
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
];

const timeoutFunction = ref(null);
const fetching = ref(false);

const getTitleForAudits = () => {
  if (props.runningOrderCell)
    return 'Audits for ' + props.runningOrderRow.title + ' on ' + props.runningOrderColumn.title;
  if (props.runningOrderColumn) return 'Audits for ' + props.runningOrderColumn.title;
  if (props.runningOrderRow) return 'Audits for ' + props.runningOrderRow.title;
  return 'Audits for ' + props.runningOrder.title;
};

const getUrlForAudits = () => {
  if (props.runningOrderCell)
    return (
      '/api/runningorders/' + props.runningOrder.id + '/running-order-cells/' + props.runningOrderCell.id + '/audits'
    );
  if (props.runningOrderColumn)
    return (
      '/api/runningorders/' +
      props.runningOrder.id +
      '/running-order-columns/' +
      props.runningOrderColumn.id +
      '/audits'
    );
  if (props.runningOrderRow)
    return (
      '/api/runningorders/' + props.runningOrder.id + '/running-order-rows/' + props.runningOrderRow.id + '/audits'
    );
  return '/api/runningorders/' + props.runningOrder.id + '/audits';
};

const loadAudits = () => {
  if (loading.value) return;
  fetching.value = true;
  loading.value = true;
  axios
    .get(getUrlForAudits(), {
      params: {
        page: page.value,
        audit_events: auditEventTypes.value.map((type) => type.id),
      },
    })
    .then((resp) => {
      if (resp.data.meta.hasOwnProperty('total')) {
        totalAudits.value = resp.data.meta.total;
      }
      if (resp.data.meta.hasOwnProperty('last_page')) {
        totalPages.value = resp.data.meta.last_page;
      } else if (resp.data.data.length > 0) {
        totalPages.value = page.value + 1;
      }
      if (resp.data.data.length) {
        audits.value = audits.value.concat(resp.data.data);
      }

      loading.value = false;
      fetching.value = false;
    })
    .catch((error) => {
      console.error(error);
    });
};

const resetAndLoad = (delay = 1000) => {
  audits.value = [];
  page.value = 1;
  clearTimeout(timeoutFunction.value);
  timeoutFunction.value = null;
  fetching.value = true;
  timeoutFunction.value = setTimeout(() => {
    clearTimeout(timeoutFunction.value);
    loadAudits();
  }, delay);
};

const showSidebar = () => {
  open.value = false;
  nextTick(() => {
    open.value = true;
  });
  resetAndLoad(0);
};

const openAudit = async (audit: RunningOrderAuditResource) => {
  selectedAudit.value = null;
  await nextTick();
  selectedAudit.value = audit;
  const possibleFields = Object.keys(audit.old_values ?? []).concat(Object.keys(audit.new_values ?? []));
  activeFields.value = allowedFields.value.filter((field) => possibleFields.includes(field));
};

watch(auditEventTypes, () => {
  resetAndLoad(0);
});

onMounted(() => {
  setTimeout(() => {
    showSidebar();
  }, 100);
});
useInfiniteScroll(
  target,
  async () => {
    // load more
    if (page.value < totalPages.value) {
      page.value += 1;
      await loadAudits();
    }
  },
  { distance: 10 }
);

const test = (audit: anyu, field: any) => {
  const d = getKey(audit, field, '');
  if (typeof d !== 'string') return String(d);
  return d.split('\n');
};

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';
</script>

<template>
  <div>
    <BaseSlideout
      v-model="open"
      small
      within-same
      :base-z-index="1000"
      @closed="$emit('closed')">
      <template #header>
        <div class="flex flex-col border-b p-edge">
          <div class="flex gap-3">
            <IconWithLoading
              icon="fa-history"
              classes="text-3xl"
              :loading="loading"></IconWithLoading>
            <h1>{{ totalAudits }} Records</h1>
          </div>
          <div class="sub-title text-textColor-soft">
            {{ getTitleForAudits() }}
          </div>
        </div>
      </template>
      <div
        ref="target"
        class="overflow-auto"
        style="height: calc(height - var(--navbar-height) - 20px)">
        <div class="flex flex-col gap-5 [&>div]:p-5">
          <div
            v-if="!audits.length"
            class="mt-50">
            <p>No audits found.</p>
          </div>
        </div>
        <div
          v-if="audits.length"
          class="mb-5 mt-2 grid gap-4 [&>div]:px-edge">
          <div
            v-for="audit in audits"
            :key="audit.id"
            class="cursor-pointer [&_.sub-title:hover]:underline hover:bg-row-hover hover:border-l-highlight border-l-2 border-l-transparent group grid grid-cols-[30px_auto] items-center gap-3 py-2 transition-all"
            @click.prevent="openAudit(audit)">
            <div class="image pull-left align-content-vertically">
              <img
                v-if="audit.user && audit.user.avatar_url"
                :src="audit.user.avatar_url"
                class="img-responsive rounded-full" />
              <img
                v-else
                :src="usePage().props.asset_url + 'assets/images/default-avatar.png'"
                class="img-responsive rounded-full" />
            </div>
            <div class="overflow-hidden pr-2">
              <div class="sub-title truncate">
                {{ audit.user ? audit.user.name : 'Unknown user' }}
                {{ audit.title }}
              </div>
              <small
                class="mt-2 text-textColor-soft"
                :title="audit.created_at">
                {{ audit.created_at_human }}
              </small>
            </div>
          </div>
          <div
            v-if="audits.length && page >= totalPages"
            class="p-5 text-center text-lg uppercase">
            All loaded
          </div>
        </div>
      </div>
    </BaseSlideout>

    <CrudModal
      v-if="selectedAudit"
      title="Audit"
      only-close-button
      @closed="selectedAudit = null">
      <div
        v-if="!loadingSelectedAudit && selectedAudit"
        class="main-content">
        <div class="sub-title mb-6">
          {{ selectedAudit.user ? selectedAudit.user.name : 'Unknown user' }}
          {{ selectedAudit.title }}
        </div>
        <div
          v-if="activeFields.length > 0"
          class="border-t hover:border-t-highlight [&_th>*]:px-2">
          <VTable>
            <template #head>
              <VTableRow head>
                <VTableCell>Attribute</VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)"> Old</VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)"> New</VTableCell>
              </VTableRow>
            </template>
            <template #default>
              <VTableRow
                v-for="field in activeFields"
                :key="field.id">
                <VTableCell main-cell>
                  <span class="capitalize">{{ field }}</span>
                </VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)">
                  <span
                    v-for="(t, idx) in test(selectedAudit.old_values, field)"
                    :key="idx">
                    <DisplayRichText :content="t" />
                    <br />
                  </span>
                </VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)">
                  <span
                    v-for="(t, idx) in test(selectedAudit.new_values, field)"
                    :key="idx">
                    <DisplayRichText :content="t" />
                    <br />
                  </span>
                </VTableCell>
              </VTableRow>
            </template>
          </VTable>
        </div>

        <div class="mt-6">
          <div class="text-xs text-textColor-soft">
            <i class="fa fa-clock mr-2" />
            <span class="mr-2 italic">when</span>
            <i class="fa fa-minus fa-sm mr-1" />
            <span class="text-sm italic">{{ selectedAudit.created_at }}</span>
          </div>
        </div>
      </div>
    </CrudModal>
  </div>
</template>
