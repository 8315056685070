import type { RunningOrderModelType } from '@/types/global';
import type {
  RunningOrderCellResource,
  RunningOrderColumnResource,
  RunningorderResource,
  RunningOrderRowResource,
} from '@/types/runningorder';

export const getRunningOrders = async (modelType: RunningOrderModelType, modelId: number) => {
  const url = '/api/runningorders';
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.get<RunningorderResource[]>(url, { params });
};
//

export const getRunningOrder = async (id: number) => {
  const url = `/api/runningorders/${id}`;
  return axios.get<RunningorderResource>(url);
};

export const patchRunningOrder = async (runningOrderId: number, data: RunningOrderUpdateData) => {
  const url = `/api/runningorders/${runningOrderId}`;
  return axios.patch<string>(url, { ...data, is_global: false });
};

export const postRunningOrder = async (data: RunningOrderData) => {
  const url = '/api/runningorders';
  const { data: runningOrder } = await axios.post<RunningorderResource>(url, {
    is_global: data.is_global,
    model_id: data.model_id,
    title: data.title,
    start: data.start,
    template_start: data.template_start,
    template_id: data.template_id,
    placable_type: data.placable_type,
    placable_id: data.placable_id,
    show_caller_id: data.show_caller_id,
    model_type: `App\\${data.model_type}`,
  });
  return runningOrder;
};

// REMOVE
export const destroyRunningOrder = async (id: number, isGlobal: boolean) => {
  const url = `/api/runningorders/${id}`;
  return axios.delete(url, {
    params: {
      is_global: isGlobal,
    },
  });
};

export const reOrderRunningOrderRows = async (
  id: number,
  sortedRowIds: Array<number | string>,
  movedRowId?: null | number | string
) => {
  const url = `/api/runningorders/${id}/update-row-orders`;
  return axios.post(url, {
    sorted_row_ids: sortedRowIds,
    moved_row_id: movedRowId,
  });
};

export const reOrderRunningOrderColumns = async (
  id: number,
  sortedColumnIds: Array<number | string>,
  movedColumnId?: null | number | string
) => {
  const url = `/api/runningorders/${id}/update-column-orders`;
  return axios.post(url, {
    sorted_column_ids: sortedColumnIds,
    moved_column_id: movedColumnId,
  });
};

type RunningOrderRowData = {
  title: string;
  duration: number;
  color: string;
  order: number | null;
  auto_next: boolean;
  no_stage_timer: boolean;
  start_on_time: boolean;
};

export const createRunningOrderRow = async (runningOrderId: number, data: RunningOrderRowData) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-rows';
  return axios.post<RunningOrderRowResource>(url, {
    title: data.title,
    color: data.color,
    order: data.order,
    duration: data.duration,
    auto_next: data.auto_next,
    no_stage_timer: data.no_stage_timer,
    start_on_time: data.start_on_time,
  });
};

export const updateRunningOrderRow = async (
  runningOrderId: number,
  data: {
    id: number;
  }
) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-rows/' + data.id;
  return axios.patch<RunningOrderRowResource>(url, {
    title: data.title,
    color: data.color,
    duration: data.duration,
    auto_next: data.auto_next,
    no_stage_timer: data.no_stage_timer,
    start_on_time: data.start_on_time,
    header: data.header,
  });
};

export const deleteRunningOrderRow = async (runningOrderId: number, rowId: number) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-rows/' + rowId;
  return axios.delete(url);
};

// Column API
export const getRunningOrderColumns = async (runningOrderId: number) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-columns';
  return axios.get<RunningOrderColumnResource[]>(url);
};

export const getRunningOrderColumn = async (runningOrderId: number, runningOrderColumnId: number) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-columns/' + runningOrderColumnId;
  return axios.get<RunningOrderColumnResource>(url);
};

type RunningOrderColumnData = {
  title: string;
  component: string;
  width: string;
  options: string;
  order: number | null;
  restrict_edit: boolean;
};

export const createRunningOrderColumn = async (runningOrderId: number, data: RunningOrderColumnData) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-columns';
  return axios.post<RunningOrderColumnResource>(url, {
    title: data.title,
    component: data.component,
    width: data.width,
    options: data.options,
    order: data.order,
    restrict_edit: data.restrict_edit,
  });
};

export const updateRunningOrderColumn = async (
  runningOrderId: number,
  data: {
    id: number;
    order?: number;
    title?: string;
    width?: string;
    restrictEdit?: boolean;
    options?: string[];
  }
) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-columns/' + data.id;
  return axios.patch<RunningOrderColumnResource>(url, {
    title: data.title,
    width: data.width,
    options: data.options,
    order: data.order,
    restrict_edit: data.restrict_edit,
  });
};
export const deleteRunningOrderColumn = async (runningOrderId: number, data: RunningOrderColumnResource) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-columns/' + data.id;
  await axios.delete<RunningOrderColumnResource>(url);
  return true;
};

// Cell API
export const getRunningOrderCells = async (runningOrderId: number) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-cells';
  return axios.get<RunningOrderCellResource[]>(url);
};

export const getRunningOrderCell = async (runningOrderId: number, runningOrderCellId: number) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-cells/' + runningOrderCellId;
  return axios.get<RunningOrderCellResource>(url);
};

type RunningOrderCellData = {
  value: string;
  id: number | null;
  running_order_row_id: number | null;
  running_order_column_id: number | null;
};

export const createRunningOrderCell = async (runningOrderId: number, data: RunningOrderCellData) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-cells';
  return axios.post<RunningOrderCellResource>(url, {
    running_order_row_id: data.running_order_row_id,
    running_order_column_id: data.running_order_column_id,
    value: data.value,
  });
};

export const updateRunningOrderCell = async (runningOrderId: number, data: RunningOrderCellResource) => {
  const url = '/api/runningorders/' + runningOrderId + '/running-order-cells/' + data.id;
  return axios.patch<RunningOrderColumnResource>(url, {
    value: data.value,
  });
};
