import type { FestivalMinimalResource } from '@/types/festival';
import type { FestivalVenueResource } from '@/types/festival-venue';

export type FestivalSchedulerCalendarEvent = {
  title: string;
  color: string;
  show_time_id: number;
  show_time_title: string | null;
  invite_id: number;
  event_id: number;
  slug: string;
  performances: {
    id: number;
    name: string;
    slug: string;
    primary: boolean;
  }[];
  festival_type_id: number | null;
  confirmed: boolean;
  start: string;
  end: string;
  resourceIds: string[];
};

export const getFestivals = async (groupId: number) => {
  return axios.get<FestivalMinimalResource[]>('/api/festivals', {
    params: {
      model_type: 'App\\Group',
      model_id: groupId,
    },
  });
};

export const getFestivalSchedulerVenues = async (festivalId: number) => {
  return axios.get<FestivalVenueResource[]>(`/api/festivals/${festivalId}/scheduler/venues`);
};

export const getFestivalSchedulerCalendar = async (festivalId: number) => {
  return axios.get<FestivalSchedulerCalendarEvent[]>('/api/festivals/' + festivalId + '/scheduler/calendar');
};
