import { usePage } from '@inertiajs/vue3';

export const isAdminOfGroup = (groupId: number, parentId?: number | null) => {
  if (!groupId) return false;
  const user = usePage().props.auth.user;
  if (user.is_admin) return true;

  const group = user.groups.find((group) => group.id === groupId);
  if (group && group.admin) return group.admin;

  if (parentId) {
    const parent = user.groups.find((group) => group.id === parentId);
    if (parent) return parent.admin;
  }
  return false;
};
export const isSuperAdminOfGroup = (groupId: number, parentId?: number | null) => {
  if (!groupId) return false;
  const user = usePage().props.auth.user;
  if (user.is_admin) return true;

  const group = user.groups.find((group) => group.id === groupId);
  if (group && group.superadmin) return group.superadmin;

  if (parentId) {
    const parent = user.groups.find((group) => group.id === parentId);
    if (parent) return parent.superadmin;
  }
  return false;
};

export const isReadOfGroup = (groupId: number, parentId?: number | null) => {
  if (!groupId) return false;
  const user = usePage().props.auth.user;
  if (user.is_admin) return true;

  const group = user.groups.find((group) => group.id === groupId);
  if (group) return group.admin || group.read;

  if (parentId) {
    const parent = user.groups.find((group) => group.id === parentId);
    if (parent) return parent.admin || parent.read;
  }
  return false;
};
